<template>
  <v-app-bar app elevation="0" color="white" class="MobileNavBar">
    <router-link to="/">
      <v-img
        alt="Logo Action Prevention"
        :src="getImage('logo.png')"
        width="50"
      />
    </router-link>
    <v-toolbar-title v-if="$vuetify.breakpoint.smAndUp">Action Prevention</v-toolbar-title>
    <v-spacer></v-spacer>
    <AvatarMenu v-if="loggedIn" />

    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-app-bar-nav-icon v-bind="attrs" v-on="on"></v-app-bar-nav-icon>
      </template>
      <v-card tile>
        <v-toolbar flat>
          <router-link to="/">
            <v-img
              alt="Logo Action Prevention"
              :src="getImage('logo.png')"
              width="50"
            />
          </router-link>
          <v-toolbar-title>Action Prevention</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.native="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-list>
          <v-list-item-group v-model="selectedItem" color="lighgray">
            <v-list-item
              v-for="item in menu.firstSection"
              :key="item.key"
              :to="item.to"
              @click.native="dialog = false"
            >
              <v-list-item-icon>
                <v-icon v-if="item.icon" color="primary">{{
                  item.icon
                }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>

        <v-divider></v-divider>

        <v-list>
          <v-list-item-group v-model="selectedItem" color="lighgray">
            <v-list-item
              v-if="!loggedIn"
              :to="menu.professionalAccess.to"
              exact
              @click.native="dialog = false"
            >
              <v-list-item-icon>
                <v-icon v-if="menu.professionalAccess.icon" color="primary">{{
                  menu.professionalAccess.icon
                }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{
                  $t(menu.professionalAccess.text)
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              :to="menu.carryOutMyDiagnosis.to"
              exact
              @click.native="dialog = false"
            >
              <v-list-item-icon>
                <v-icon v-if="menu.carryOutMyDiagnosis.icon" color="primary">{{
                  menu.carryOutMyDiagnosis.icon
                }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{
                  $t(menu.carryOutMyDiagnosis.text)
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-dialog>
  </v-app-bar>
</template>

<script>
import AvatarMenu from "./AvatarMenu.vue";
import { createNamespacedHelpers } from "vuex";
import { mapGetters } from "vuex";

const { mapState } = createNamespacedHelpers("navbar");

export default {
  components: {
    AvatarMenu
  },
  data() {
    return {
      dialog: false,
      selectedItem: 1,
    };
  },
  computed: {
    ...mapGetters({
      getImage: "getImage",
      loggedIn: "login/loggedIn",
    }),
    ...mapState(["menu"]),
  },
};
</script>

<style lang="less" scoped>
@import "../../styles/NavBar/mobile-nav-bar.less";
</style>
