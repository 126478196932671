<template>
  <v-container fluid class="OurVision--Container">
    <v-row justify="center" class="mt-14">
      <span class="OurVision--Title">{{
        $t("App.About.OurVision.Title")
      }}</span>
    </v-row>
    <v-row justify="center" class="mt-14">
      <v-col cols="12" md="5" class="OurVision--Description">{{
        $t("App.About.OurVision.LeftDescription")
      }}</v-col>
      <v-col cols="12" md="5" class="OurVision--Description">{{
        $t("App.About.OurVision.RightDescription")
      }}</v-col>
    </v-row>
    <v-row justify="center" class="my-10">
      <div class="OurVision--BtnContainer">
        <v-btn
          :to="menu.carryOutMyDiagnosis.to"
          color="#37A3C6"
          tile
          x-large
          class="d-flex justify-center white--text"
        >
          <span>
            {{ $t(menu.carryOutMyDiagnosis.text) }}
          </span>
        </v-btn>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState } = createNamespacedHelpers("navbar");

export default {
  computed: {
    ...mapState(["menu"]),
  },
};
</script>

<style lang="less" scoped>
@import "../../styles/About/our-vision.less";
</style>
