<template>
  <v-container fluid class="pa-0">
    <!-- <v-row no-gutters>
      <v-container
        fluid
        class="CGV--Container"
        :style="{ backgroundImage: `url(${getImage('back-home-page.jpeg')})` }"
      >
        <v-row justify="center">
          <span class="CGV--Title">Condition générales de vente</span>
        </v-row>
        <v-row justify="center">
          <span class="CGV--Subtitle">
            Prestation de services auprès de consommateurs non professionnels
            sur internet
          </span>
        </v-row>
      </v-container>
    </v-row>
    <v-row justify="center">
      <v-col cols="10">
        <CguTitle number="1" title="champ d'application" />
        <p class="CGV--Text">
          Les présentes Conditions Générales de Vente (ci-après « <b>CGV</b> »)
          s&#39;appliquent, sans restriction ni réserve à tout achat des
          services (ci-après « <b>Services</b> ») proposés par ACTION PRÉVENTION
          (ci-après « <b>Le prestataire</b> ») auprès de consommateurs et
          d’acheteurs non professionnels (ci-après « <b>Le Client</b> ») sur son
          site Internet <u>https://action-prevention.com</u> (ci-après «
          <b>Le Site</b> »).
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Les caractéristiques principales des Services, sont présentées sur le
          Site.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Le Client est tenu d&#39;en prendre connaissance avant toute passation
          de commande. Le choix et l&#39;achat d&#39;un Service est de la seule
          responsabilité du Client.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Ces conditions s&#39;appliquent à l&#39;exclusion de toutes autres
          conditions, et notamment celles applicables pour d&#39;autres circuits
          de commercialisation des Services.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Elles sont susceptibles d&#39;être complétées par des conditions
          particulières, énoncées sur le site Internet, avant toute transaction
          avec le Client.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Les CGV précisent notamment les conditions de commande, de paiement,
          de livraison et de gestion des retours éventuels des Services
          commandés par les Clients. Elles sont susceptibles d’être complétées
          par des conditions particulières énoncées sur le Site avant toute
          transaction avec le Client.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Les CGV sont accessibles à tout moment sur le Site et s’appliquent à
          l’exclusion de toutes autres conditions et autres documents. Elles
          prévaudront, le cas échéant, sur toute autre version ou tout autre
          document contradictoire. Les CGV pourront également faire l’objet de
          modifications ultérieures.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Les présentes CGV sont valables pour une durée indéterminée.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Sauf preuve contraire, les données enregistrées dans le système
          informatique du Prestataire constituent la seule preuve valable de
          l’ensemble des transactions conclues avec le Client.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Conformément à la loi Informatique et Libertés du 6 janvier 1978,
          renforcée et complétée par le RGPD (règlement général sur la
          protection des données) entré en vigueur le 25 mai 2018, le Client
          dispose, à tout moment, d&#39;un droit d&#39;accès, de rectification,
          d&#39;opposition, d&#39;effacement et de portabilité de l&#39;ensemble
          de ses données personnelles en écrivant, par courrier et en justifiant
          de son identité, au Délégué à la Protection des Données (Voir
          Politique de confidentialité).
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          <b>
            Le Client déclare avoir pris connaissance des présentes CGV et les
            avoir acceptées en cochant la case prévue à cet effet lors de la
            procédure de commande en ligne sur le Site. La validation de la
            commande par le Client vaut acceptation sans restriction ni réserve
            des présentes CGV. Par l’acceptation, le Client reconnaît avoir la
            capacité requise pour contracter et acquérir les Services proposés
            sur le Site.
          </b>
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Ces Conditions Générales de Vente pouvant faire l&#39;objet de
          modifications ultérieures, la version applicable à l&#39;achat du
          Client est celle en vigueur sur le site internet à la date de
          passation de la commande.
        </p>
      </v-col>
      <v-col cols="10">
        <CguTitle number="2" title="commandes" />
        <CguSubtitle number="2.1" title="passation de la commande" />
        <p class="CGV--Text">
          Le Client sélectionne sur le Site les Services qu’il désire commander, selon les modalités suivantes :
          <ul>
            <li>
              Définir un ciblage :
              <ul>
                <li>
                  Le prix de l’abonnement dépend du nombre d’options sélectionnées
                  <ul>
                    <li>
                      Choix de la zone géographique d’intervention
                    </li>
                    <li>
                      Choix de la procédure préventive ou collective d’intervention
                    </li>
                    <li>
                      Choix du chiffre d’affaires ciblé
                    </li>
                  </ul>
                </li>
                <li>
                  Sélection d’un moyen de paiement
                </li>
              </ul>
            </li>
            <li>
              Devenir partenaire:
              <ul>
                <li>
                  Adhérer au programme de partenariat
                </li>
                <li>
                  Inscrire ses coordonnées bancaires afin de récupérer une partie de l’argent perçu sur le site de l’utilisateur
                </li>
              </ul>
            </li>
          </ul>
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Les informations contractuelles sont présentées en langue française et font l&#39;objet d&#39;une
confirmation au plus tard au moment de la validation de la commande par le Client.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          L&#39;enregistrement d&#39;une commande sur le site du Prestataire est réalisé lorsque le Client accepte les
présentes Conditions Générales de Vente en cochant la case prévue à cet effet et valide sa
commande.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Le Client a la possibilité de vérifier le détail de sa commande, son prix total et de corriger d'éventuelles erreurs avant de confirmer son acceptation 
          (article 1127-2 du Code Civil). Cette validation implique l'acceptation de l'intégralité des présentes Conditions Générales de Vente et constituent une 
          preuve du contrat de vente.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Il appartient donc au Client de vérifier l&#39;exactitude de la commande et de signaler immédiatement
toute erreur.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          La vente de Services (ci-après la « <b>Prestation</b> ») ne sera considérée comme définitive qu&#39;après l&#39;envoi
au Client de la confirmation de l&#39;acceptation de la commande par le Prestataire, par courrier
électronique et après encaissement par celui-ci de l&#39;intégralité du prix.
        </p>
      </v-col>
      <v-col cols="10">
        <CguSubtitle number="2.2" title="hypothèses de refus de commande par le prestataire" />
        <p class="CGV--Text">
          Le Prestataire se réserve le droit de refuser la Commande ou d’annuler la Prestation en cas de litige
avec le Client relatif au paiement d’une commande antérieure.
        </p>
      </v-col>
      <v-col cols="10">
        <CguSubtitle number="2.3" title="modification et annulation de la commande par le client" />
        <p class="CGV--Text">
          Les éventuelles modifications de la commande par le Client ne pourront être prises en compte par le
Prestataire qu’à la fin de la période d’abonnement en cours, d’une durée de 1 mois, tacitement
renouvelée, à compter de la date de la commande.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Le cas échéant, ces modifications donneront lieu à un ajustement du prix.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Une fois confirmée et acceptée par le Prestataire, dans les conditions ci-dessus décrites, la
commande ne peut être annulée, hors cas de force majeure.
        </p>
      </v-col>
      <v-col cols="10">
        <CguSubtitle number="2.4" title="reconduction tacite" />
        <p class="CGV--Text">
          La passation d&#39;une commande sur le Site implique la conclusion d&#39;un contrat d&#39;une durée de 1 mois
pour les contrats d’abonnement (de mise en relation) et de 1 an pour les contrats de partenariat
reconduite tacitement pour une même durée.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Aux termes de l&#39;article L215 -1 du Code de la consommation, littéralement reproduit :
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          <i>« Pour les contrats de prestations de services conclus pour une durée déterminée avec une clause de
reconduction tacite, le professionnel prestataire de services informe le consommateur par écrit, par
lettre nominative ou courrier électronique dédiés, au plus tôt trois mois et au plus tard un mois avant
le terme de la période autorisant le rejet de la reconduction, de la possibilité de ne pas reconduire le
contrat qu&#39;il a conclu avec une clause de reconduction tacite. Cette information, délivrée dans des
termes clairs et compréhensibles, mentionne, dans un encadré apparent, la date limite de non-
reconduction.
Lorsque cette information ne lui a pas été adressée conformément aux dispositions du premier alinéa,
le consommateur peut mettre gratuitement un terme au contrat, à tout moment à compter de la
date de reconduction.
Les avances effectuées après la dernière date de reconduction ou, s&#39;agissant des contrats à durée
indéterminée, après la date de transformation du contrat initial à durée déterminée, sont dans ce cas
remboursées dans un délai de trente jours à compter de la date de résiliation, déduction faite des
sommes correspondant, jusqu&#39;à celle-ci, à l&#39;exécution du contrat.
Les dispositions du présent article s&#39;appliquent sans préjudice de celles qui soumettent légalement
certains contrats à des règles particulières en ce qui concerne l&#39;information du consommateur. »</i>
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          L&#39;article L215-2 du Code de la consommation exclut l&#39;application de l&#39;article L215-1 susmentionné
aux exploitants des services d&#39;eau potable et d&#39;assainissement. : <i>« Les dispositions du présent
chapitre ne sont pas applicables aux exploitants des services d&#39;eau potable et d&#39;assainissement. »</i>
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          De même, en vertu de l&#39;article L215-3 du Code de la consommation <i>« Les dispositions du présent
chapitre sont également applicables aux contrats conclus entre des professionnels et des non-
professionnels. »</i>
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          L&#39;article L241-3 dispose que <i>« Lorsque le professionnel n&#39;a pas procédé au remboursement dans les
conditions prévues à l&#39;article L. 215-1, les sommes dues sont productives d&#39;intérêts au taux légal. ».</i>
        </p>
      </v-col>
      <v-col cols="10">
        <CguTitle number="3" title="prix" />
        <p class="CGV--Text">
          La passation d&#39;une commande sur le Site implique la conclusion d&#39;un contrat d&#39;une durée de 1 mois
pour les contrats d’abonnement (de mise en relation) et de 1 an pour les contrats de partenariat
reconduite tacitement pour une même durée.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Les Services proposés par le Prestataire sont fournis aux tarifs en vigueur sur le Site. Les prix sont
exprimés en Euros, HT et TTC.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          <b>Ces tarifs sont fermes et non révisables pendant leur période de validité, telle qu’indiqués sur le
Site.</b>
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Le Prestataire se réserve le droit de modifier les prix à tout moment.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Ils ne comprennent pas les frais de traitement et de gestion, pouvant être facturés en supplément,
dans les conditions indiquées sur le Site et calculés préalablement à la passation de la commande.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Le paiement demandé au Client correspond au montant total de l&#39;achat, y compris ces frais.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Une facture est établie par le Prestataire et remise au Client lors de la fourniture des Services
commandés.
        </p>
      </v-col>
      <v-col cols="10">
        <CguTitle number="4" title="conditions de paiement" />
        <p class="CGV--Text">
          Le prix est payable comptant, en totalité au jour de la passation de la commande par le Client par
voie de paiement sécurisé <i>(Logiciel de paiement STRIPE)</i> par carte bancaire <i>(Visa, MasterCard,
American Express, autres cartes bleues, Apple Pay…)</i>
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Le paiement par carte bancaire est irrévocable, sauf en cas d&#39;utilisation frauduleuse de la carte. Dans
ce cas, le Client peut contacter le Prestataire pour demander l&#39;annulation du paiement et la
restitution des sommes correspondantes.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Les paiements effectués par le Client ne seront considérés comme définitifs qu’après encaissement
effectif des sommes dues par le Prestataire. Aucun frais supplémentaire supérieur aux coûts
supportés ne pourra être facturé par le Prestataire pour l’utilisation d’un moyen de paiement.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Le Prestataire ne sera pas tenu de procéder à la fourniture des Services commandés par le Client si le
prix ne lui a pas été préalablement réglé en totalité dans les conditions et ci-dessus indiquées.
        </p>
      </v-col>
      <v-col cols="10">
        <CguTitle number="5" title="fourniture des prestations" />
        <p class="CGV--Text">
          Les Services commandés par le Client seront fournis dans un délai maximum de 24h à compter de la
validation définitive de la commande du Client, dans les conditions prévues aux présentes Conditions
Générales de Vente, potentiellement complétées par les conditions particulières de vente remises au
Client conjointement aux présentes Conditions Générales de Vente, à l&#39;adresse indiquée par le Client
lors de sa commande sur le Site.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Le Prestataire s&#39;engage à faire ses meilleurs efforts pour fournir les Services commandés par le
Client, dans le cadre d&#39;une obligation de moyen et dans les délais ci-dessus précisés.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Si les services commandés n&#39;ont pas été fournis dans un délai de 72h après la date indicative ci-
dessus précisée, pour toute autre cause que la force majeure ou le fait du Client, la vente pourra être
résolue à la demande écrite du Client dans les conditions prévues aux articles L 216-2, L 216-3 et
L241-4 du Code de la consommation. Les sommes versées par le Client lui seront alors restituées au
plus tard dans les quatorze jours qui suivent la date de dénonciation du contrat, à l&#39;exclusion de
toute indemnisation ou retenue.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          L&#39;identification du Prestataire est la suivante :
          <ul>
            <li>
              Dénomination : <b>ACTION PRÉVENTION</b>
            </li>
            <li>
              Forme sociale : <b>Association Loi 1901</b>
            </li>
            <li>
              Siège social : <b>19 bis Cours des Arts et Métiers – 13100 AIX EN PROVENCE</b>
            </li>
            <li>
              Numéro RNA : <b>W131017514</b>
            </li>
          </ul>
        </p>
      </v-col>
      <v-col cols="10">
        <CguTitle number="6" title="droit de rétractation" />
        <p class="CGV--Text">
          Compte tenu de la nature des services fournis, et conformément à l’Article L221-28 1° du Code de
Commerce, les commandes passées par le Client ne bénéficient pas du droit de rétractation.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Le contrat est donc conclu de façon définitive dès la passation de la commande par le Client selon les
modalités précisées aux présentes Conditions générales de Vente.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Le Client déclare avoir pris connaissance de cette absence de droit et y renoncer expressément en
cochant la case prévue à cet effet lors de la procédure de commande en ligne sur le Site.
        </p>
      </v-col>
      <v-col cols="10">
        <CguTitle number="7" title="responsabilité du prestataire - garantie" />
        <p class="CGV--Text">
          Le Prestataire garantit, conformément aux dispositions légales et sans paiement complémentaire, le
Client, contre tout défaut de conformité ou vice caché, provenant d&#39;un défaut de conception ou de
réalisation des Services commandés dans les conditions et selon les modalités définies en annexe
aux présentes Conditions Générales de Vente.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Afin de faire valoir ses droits, le Client devra informer le Prestataire, par écrit, de l&#39;existence des vices
ou défauts de conformité dans un délai maximum de 14 jours à compter de la fourniture des
Services.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Le Prestataire remboursera ou rectifiera ou fera rectifier (dans la mesure du possible) les services
jugés défectueux dans les meilleurs délais et au plus tard dans les 8 jours suivant la constatation par
le Prestataire du défaut ou du vice. Le remboursement s&#39;effectuera par crédit sur le compte bancaire
du Client ou par chèque bancaire adressé au Client.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          La garantie du Prestataire est limitée au remboursement des Services effectivement payés par le
Client et le Prestataire ne pourra être considéré comme responsable ni défaillant pour tout retard ou
inexécution consécutif à la survenance d&#39;un cas de force majeure habituellement reconnu par la
jurisprudence française.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Les Services fournis par l&#39;intermédiaire du Site sont conformes à la réglementation en vigueur en
France. La responsabilité du Prestataire ne saurait être engagée en cas de non-respect de la
législation du pays dans lequel les Services sont fournis, qu&#39;il appartient au Client, qui est seul
responsable du choix des Services demandés, de vérifier.
        </p>
      </v-col>
      <v-col cols="10">
        <CguTitle number="8" title="protection des données personnelles" />
        <p class="CGV--Text">
          En application de la loi 78-17 du 6 janvier 1978 modifiée par la loi n°2018-493 du 20 juin 2018, il est
rappelé que les données nominatives demandées au Client sont nécessaires au traitement de sa
commande et à l&#39;établissement des factures, notamment.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Ces données peuvent être communiquées aux éventuels partenaires du Prestataire chargés de
l&#39;exécution, du traitement, de la gestion et du paiement des commandes.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Le traitement des informations communiquées par l&#39;intermédiaire du Site répond aux exigences
légales en matière de protection des données personnelles, le système d&#39;information utilisé assurant
une protection optimale de ces données.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Le Client dispose, conformément aux réglementations nationales et européennes en vigueur d&#39;un
droit d&#39;accès permanent, de modification, de rectification, d&#39;opposition de portabilité et de
limitation du traitement s&#39;agissant des informations le concernant.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Ce droit peut être exercé selon les conditions et modalités définies dans la politique de
confidentialité du Site.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          <b>Voir notre Politique de Confidentialité</b>
        </p>
      </v-col>
      <v-col cols="10">
        <CguTitle number="9" title="propriete intellectuelle" />
        <p class="CGV--Text">
          Le contenu du Site est la propriété du Prestataire et de ses partenaires et est protégé par les lois
françaises et internationales relatives à la propriété intellectuelle.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Toute reproduction totale ou partielle de ce contenu, sans l’accord du Prestataire et conditionnée à
une contrepartie, est strictement interdite et est susceptible de constituer un délit de contrefaçon.
        </p>
      </v-col>
      <v-col cols="10">
        <CguTitle number="10" title="imprevision" />
        <p class="CGV--Text">
          En cas de changement de circonstances imprévisibles lors de la conclusion du contrat,
conformément aux dispositions de l&#39;article 1195 du Code civil, la Partie qui n&#39;a pas accepté
d&#39;assumer un risque d&#39;exécution excessivement onéreuse peut demander une renégociation du
contrat à son cocontractant.
        </p>
      </v-col>
      <v-col cols="10">
        <CguTitle number="11" title="force majeure" />
        <p class="CGV--Text">
          Les Parties ne pourront être tenues pour responsables si la non-exécution ou le retard dans
l&#39;exécution de l&#39;une quelconque de leurs obligations, telles que décrites dans les présentes découle
d&#39;un cas de force majeure, au sens de l&#39;article 1218 du Code civil.
        </p>
      </v-col>
      <v-col cols="10">
        <CguTitle number="12" title="droit applicable - langue" />
        <p class="CGV--Text">
          Les présentes Conditions générales de vente et les opérations qui en découlent sont régies par le
droit français.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Elles sont rédigées en langue française. Dans le cas où elles seraient traduites en une ou plusieurs
langues, seul le texte français ferait foi en cas de litige.
        </p>
      </v-col>
      <v-col cols="10">
        <CguTitle number="13" title="litiges" />
        <p class="CGV--Text">
          Tous les litiges auxquels les opérations d&#39;achat et de vente conclues en application des présentes
conditions générales de vente pourraient donner lieu, concernant tant leur validité, leur
interprétation, leur exécution, leur résiliation, leurs conséquences et leurs suites et qui n&#39;auraient pu
être résolues entre le Prestataire et le client seront soumis aux tribunaux compétents dans les
conditions de droit commun.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Le Client est informé qu&#39;il peut en tout état de cause recourir à une médiation conventionnelle,
notamment auprès de la Commission de la médiation de la consommation (C. consom. art. L 612-1)
ou auprès des instances de médiation sectorielles existantes, ou à tout mode alternatif de règlement
des différends (conciliation, par exemple) en cas de contestation.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Le client, constatant qu&#39;une violation au RGPD aurait été commise, a la possibilité de mandater une
          association ou un organisme mentionné au IV de l&#39;article 43 ter de la loi informatique et liberté de
          1978, afin d&#39;obtenir contre le responsable de traitement ou sous-traitant, réparation devant une
          juridiction civile ou administrative ou devant la commission nationale de l&#39;informatique et des
          libertés.
        </p>
      </v-col>
      <v-col cols="10">
        <CguTitle number="14" title="information precontractuelle - acceptation du client" />
        <p class="CGV--Text">
          Le Client reconnaît avoir eu communication, préalablement à la passation de sa commande et à la
          conclusion du contrat, d&#39;une manière lisible et compréhensible, des présentes Conditions Générales
          de Vente et de toutes les informations listées à l&#39;article L. 221-5 du Code de la consommation, et
          notamment les informations suivantes :
          <ul>
            <li>les caractéristiques essentielles des Services</li>
            <li>le prix des Services et des frais annexes</li>
            <li>en cas d&#39;absence d&#39;exécution immédiate du contrat, la date ou le délai auquel le Prestataires&#39;engage à fournir les Services commandés</li>
            <li>les informations relatives à l’identité et aux coordonnées du Prestataire (Préambule des présentes)</li>
            <li>les informations relatives aux garanties légales, aux modalités de leur mise en œuvre et aux autres conditions contractuelles</li>
            <li>la possibilité de recourir à une médiation conventionnelle en cas de litige</li>
            <li>les informations relatives au droit de rétractation</li>
            <li>les moyens de paiement acceptés</li>
          </ul>
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Le fait pour une personne physique (ou morale), de commander sur le site Internet ACTION
          PRÉVENTION (<u>https://action-prevention.com</u>) emporte adhésion et acceptation pleine et entière des
          présentes Conditions Générales de Vente et obligation au paiement des Services commandés, ce qui
          est expressément reconnu par le Client, qui renonce, notamment, à se prévaloir de tout document
          contradictoire, qui serait inopposable au Prestataire.
        </p>
      </v-col>
    </v-row> -->
    <v-row no-gutters>
      <v-container
        fluid
        class="CGV--Container"
        :style="{ backgroundImage: `url(${getImage('back-home-page.jpeg')})` }"
      >
        <v-row justify="center">
          <span class="CGV--Title">Condition générales de vente</span>
        </v-row>
        <v-row justify="center">
          <span class="CGV--Subtitle">
            Prestation de services entre professionnels sur internet
          </span>
        </v-row>
      </v-container>
    </v-row>
    <v-row justify="center">
      <v-col cols="10">
        <CguTitle number="1" title="champ d'application" />
        <p class="CGV--Text">
          Les présentes Conditions Générales de Vente constituent, conformément à l&#39;article L 441-1 du Code
du Commerce, le socle unique de la relation commerciale entre les parties.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Elles ont pour objet de définir les conditions dans lesquelles ACTION PRÉVENTION (ci-après « <b>Le
          Prestataire</b> ») fournit aux Clients professionnels (ci-après « <b>Les Clients ou le Client</b> ») qui lui en font
          la demande, via le site internet du Prestataire, par contact direct ou via un support papier, les
          services suivants :
          <ul>
            <li>Mise en relation avec des clients</li>
            <li>Pré-rédaction d’actes juridiques</li>
            <li>Possibilité d’intégration de la solution sur leur site internet</li>
          </ul>
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Elles s&#39;appliquent, sans restriction ni réserve, à tous les Services rendus par le Prestataire auprès des
          Clients de même catégorie, quelles que soit les clauses pouvant figurer sur les documents du Client,
          et notamment ses conditions générales d&#39;achat.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Conformément à la réglementation en vigueur, ces Conditions Générales de Vente sont
          systématiquement communiquées à tout Client qui en fait la demande, pour lui permettre de passer
          commande auprès du Prestataire. Elles sont également communiquées à tout Client préalablement à
          la conclusion d&#39;une convention unique visée aux articles L 441-3 et suivants du Code du Commerce,
          dans les délais légaux.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Toute commande de Services implique, de la part du Client, l&#39;acceptation des présentes Conditions
          Générales de Vente et des conditions générales d&#39;utilisation du site internet du Prestataire pour les
          commandes électroniques.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Les renseignements figurant sur les catalogues, prospectus et tarifs du Prestataire sont donnés à
          titre indicatif et sont révisables à tout moment.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Le Prestataire est en droit d&#39;y apporter toutes modifications qui lui paraîtront utiles.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Les présentes CGV sont valables pour une durée indéterminée.
        </p>
      </v-col>
      <v-col cols="10">
        <CguTitle number="2" title="commandes" />
        <CguSubtitle number="2.1" title="passation de la commande" />
        <p class="CGV--Text">
          L&#39;enregistrement d&#39;une commande sur le site du Prestataire est réalisé lorsque le Client accepte les
          présentes Conditions Générales de Vente en cochant la case prévue à cet effet et valide sa
          commande.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Le Client a la possibilité de vérifier le détail de sa commande, son prix total et de corriger
          d&#39;éventuelles erreurs avant de confirmer son acceptation (article 1127-2 du Code Civil). Cette
          validation implique l&#39;acceptation de l&#39;intégralité des présentes Conditions Générales de Vente et
          constituent une preuve du contrat de vente.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Il appartient donc au Client de vérifier l&#39;exactitude de la commande et de signaler immédiatement
          toute erreur.
          La vente de Services (ci-après la « <b>Prestation</b> ») ne sera considérée comme définitive qu&#39;après l&#39;envoi
          au Client de la confirmation de l&#39;acceptation de la commande par le Prestataire, par courrier
          électronique et après encaissement par celui-ci de l&#39;intégralité du prix.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Les données enregistrées dans le système informatique du Prestataire constituent la preuve de
          l&#39;ensemble des transactions conclues avec le Client.
        </p>
      </v-col>
      <v-col cols="10">
        <CguSubtitle number="2.2" title="hypothèses de refus de commande par le prestataire" />
        <p class="CGV--Text">
          Le Prestataire se réserve le droit de refuser la Commande ou d’annuler la Prestation en cas de litige
          avec le Client relatif au paiement d’une commande antérieure.
        </p>
      </v-col>
      <v-col cols="10">
        <CguSubtitle number="2.3" title="modification et annulation de la commande par le client" />
        <p class="CGV--Text">
          Les éventuelles modifications de la commande par le Client ne pourront être prises en compte par le
          Prestataire qu’à la fin de la période d’abonnement en cours, d’une durée de 1 mois, tacitement
          renouvelée, à compter de la date de la commande.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Le cas échéant, ces modifications donneront lieu à un ajustement du prix.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Une fois confirmée et acceptée par le Prestataire, dans les conditions ci-dessus décrites, la
          commande ne peut être annulée, hors cas de force majeure.
        </p>
      </v-col>
      <v-col cols="10">
        <CguSubtitle number="2.4" title="reconduction tacite" />
        <p class="CGV--Text">
          La passation d&#39;une commande sur le Site implique la conclusion d&#39;un contrat d&#39;une durée de 1 mois
          pour les contrats d’abonnement (de mise en relation) et de 1 an pour les contrats de partenariat
          reconduite tacitement pour une même durée.
        </p>
      </v-col>
      <v-col cols="10">
        <CguTitle number="3" title="prix" />
        <p class="CGV--Text">
          La passation d&#39;une commande sur le Site implique la conclusion d&#39;un contrat d&#39;une durée de 1 mois
          pour les contrats d’abonnement (de mise en relation) et de 1 an pour les contrats de partenariat
          reconduite tacitement pour une même durée.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Les prestations de services sont fournies aux tarifs du Prestataire en vigueur au jour de la passation
          de la commande, selon le barème du Prestataire.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Les tarifs s&#39;entendent nets et HT dans la devise Euros (€).
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Une facture est établie par le Prestataire et remise au Client lors de chaque fourniture de Services.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Pour chaque partenariat entraînant l’intégration de l’outil Action Prévention en marque blanche sur un site internet, il sera mensuellement procédé au reversement de 30% des revenus généré via le site partenaire.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Il convient, en ce sens, pour chaque partenaire d’indiquer son Relevé d’identité bancaire avant de procéder au paiement de son abonnement annuel.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Une facture mensuelle, faisant état du détail de la TVA, sera mensuellement réalisée.
        </p>
      </v-col>
      <v-col cols="10">
        <CguTitle number="4" title="conditions de règlement" />
        <p class="CGV--Text">
          Le prix est payable comptant, en totalité au jour de la passation de la commande par le Client par
          voie de paiement sécurisé <i>(Logiciel de paiement STRIPE)</i> par carte bancaire <i>(Visa, MasterCard,
          American Express, autres cartes bleues, Apple Pay…)</i>
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Le paiement par carte bancaire est irrévocable, sauf en cas d&#39;utilisation frauduleuse de la carte. Dans
          ce cas, le Client peut contacter le Prestataire pour demander l&#39;annulation du paiement et la
          restitution des sommes correspondantes.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Les paiements effectués par le Client ne seront considérés comme définitifs qu’après encaissement
          effectif des sommes dues par le Prestataire. Aucun frais supplémentaire supérieur aux coûts
          supportés ne pourra être facturé par le Prestataire pour l’utilisation d’un moyen de paiement.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Le Prestataire ne sera pas tenu de procéder à la fourniture des Services commandés par le Client si le
          prix ne lui a pas été préalablement réglé en totalité dans les conditions et ci-dessus indiquées.
        </p>
      </v-col>
      <v-col cols="10">
        <CguTitle number="5" title="fourniture des prestations" />
        <p class="CGV--Text">
          Les Services commandés par le Client seront fournis dans un délai maximum de 24h à compter de la
          validation définitive de la commande du Client, dans les conditions prévues aux présentes CGV
          potentiellement complétées par les conditions particulières de vente remises au Client
          conjointement aux présentes Conditions Générales de Vente, à l&#39;adresse indiquée par le Client lors
          de sa commande sur le Site.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Le Prestataire s&#39;engage à faire ses meilleurs efforts pour fournir les Services commandés par le
          Client, dans le cadre d&#39;une obligation de moyen et dans les délais ci-dessus précisés.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Ce délai ne constitue pas un délai de rigueur et le Prestataire ne pourra voir sa responsabilité
          engagée à l&#39;égard du Client en cas de retard dans la fourniture des Services n&#39;excédant pas 72h.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          En cas de retard supérieur à 72h, le Client pourra demander la résolution de la vente. Les sommes
          versées par le Client lui seront alors restituées au plus tard dans les quatorze jours qui suivent la date
          de dénonciation du contrat, à l&#39;exclusion de toute indemnisation ou retenue.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          La responsabilité du Prestataire ne pourra en aucun cas être engagée en cas de retard ou de
          suspension de la fourniture de la prestation imputable au Client, ou en cas de force majeure.
        </p>
      </v-col>
      <v-col cols="10">
        <CguTitle number="6" title="responsabilité du prestataire - garantie" />
        <p class="CGV--Text">
          Le Prestataire garantit, conformément aux dispositions légales, le Client, contre tout défaut de
          conformité ou vice caché, provenant d&#39;un défaut de conception ou de fourniture desdits Services à
          l&#39;exclusion de toute négligence ou faute du Client.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          La responsabilité du Prestataire ne peut être engagée qu&#39;en cas de faute ou de négligence prouvée
          et est limitée aux préjudices directs à l&#39;exclusion de tout préjudice indirect, de quelque nature que ce
          soit.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Afin de faire valoir ses droits, le Client devra informer le Prestataire, par écrit, de l&#39;existence des vices
          ou défauts de conformité dans un délai maximum de 14 jours à compter de la fourniture des
          Services.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Le Prestataire rectifiera ou fera rectifier, à ses frais exclusifs, selon les modalités adéquates et
          agréées par le Client, les Services jugés défectueux.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          En tout état de cause, au cas où la responsabilité du Prestataire serait retenue, la garantie du
          Prestataire serait limitée au montant HT payé par le Client pour la fourniture des Services.
        </p>
      </v-col>
      <v-col cols="10">
        <CguTitle number="7" title="droit de propriété intellectuelle" />
        <p class="CGV--Text">
          Le contenu du Site est la propriété du Prestataire et de ses partenaires et est protégé par les lois
          françaises et internationales relatives à la propriété intellectuelle.
          Toute reproduction totale ou partielle de ce contenu, sans l’accord du Prestataire et conditionnée à
          une contrepartie, est strictement interdite et est susceptible de constituer un délit de contrefaçon.
        </p>
      </v-col>
      <v-col cols="10">
        <CguTitle number="8" title="protection des données personnelles" />
        <p class="CGV--Text">
          En application de la loi 78-17 du 6 janvier 1978 modifiée par la loi n°2018-493 du 20 juin 2018, il est
          rappelé que les données nominatives demandées au Client sont nécessaires au traitement de sa
          commande et à l&#39;établissement des factures, notamment.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Ces données peuvent être communiquées aux éventuels partenaires du Prestataire chargés de
l&#39;exécution, du traitement, de la gestion et du paiement des commandes.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Le traitement des informations communiquées par l&#39;intermédiaire du Site répond aux exigences
légales en matière de protection des données personnelles, le système d&#39;information utilisé assurant
une protection optimale de ces données.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Le Client dispose, conformément aux réglementations nationales et européennes en vigueur d&#39;un
droit d&#39;accès permanent, de modification, de rectification, d&#39;opposition de portabilité et de
limitation du traitement s&#39;agissant des informations le concernant.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Ce droit peut être exercé selon les conditions et modalités définies dans la politique de
confidentialité du Site.
        </p>
      </v-col>
      <v-col cols="10">
        <CguTitle number="9" title="droit de rétractation" />
        <p class="CGV--Text">
          L&#39;acheteur étant un professionnel achetant dans le cadre et pour les besoins de sa profession, il n&#39;y a
pas lieu d&#39;appliquer le droit de rétractation prévu par le code de la consommation.
        </p>
      </v-col>
      <v-col cols="10">
        <CguTitle number="10" title="imprévision" />
        <p class="CGV--Text">
          En cas de changement de circonstances imprévisibles lors de la conclusion du contrat,
conformément aux dispositions de l&#39;article 1195 du Code civil, la Partie qui n&#39;a pas accepté
d&#39;assumer un risque d&#39;exécution excessivement onéreuse peut demander une renégociation du
contrat à son cocontractant.
        </p>
      </v-col>
      <v-col cols="10">
        <CguTitle number="11" title="force majeure" />
        <p class="CGV--Text">
          Les Parties ne pourront être tenues pour responsables si la non-exécution ou le retard dans
l&#39;exécution de l&#39;une quelconque de leurs obligations, telles que décrites dans les présentes découle
d&#39;un cas de force majeure, au sens de l&#39;article 1218 du Code civil ou d&#39;aléas sanitaires ou climatiques
exceptionnels indépendants de la volonté des Parties.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Pendant cette suspension, les Parties conviennent que les frais engendrés par la situation seront
répartis par moitié.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Les parties se rapprocheront pour examiner l&#39;incidence de l&#39;événement et convenir des conditions
dans lesquelles l&#39;exécution du contrat sera poursuivie. Si le cas de force majeure a une durée
supérieure à trois mois, les présentes conditions générales pourront être résiliées par la partie lésée.
        </p>
      </v-col>
      <v-col cols="10">
        <CguTitle number="12" title="non-validation partielle" />
        <p class="CGV--Text">
          Si une ou plusieurs stipulations des présentes conditions générales sont tenues pour non valides ou
déclarées telles en application d&#39;une loi, d&#39;un règlement ou à la suite d&#39;une décision définitive d&#39;une
juridiction compétente, les autres stipulations garderont toute leur force et leur portée.
        </p>
      </v-col>
      <v-col cols="10">
        <CguTitle number="13" title="non-renonciation" />
        <p class="CGV--Text">
          Le fait pour l&#39;une des parties de ne pas se prévaloir d&#39;un manquement par l&#39;autre partie à l&#39;une
quelconque des obligations visées dans les présentes conditions générales ne saurait être interprété
pour l&#39;avenir comme une renonciation à l&#39;obligation en cause.
        </p>
      </v-col>
      <v-col cols="10">
        <CguTitle number="14" title="modalités de règlement des litiges" />
        <CguSubtitle number="14.1" title="clause d'arrangement préalable" />
        <p class="CGV--Text">
          En vue de trouver ensemble une solution à tout litige qui surviendrait dans l&#39;exécution du présent
contrat, les contractants conviennent de se réunir dans les sept jours à compter de l&#39;envoi de la
réception d&#39;une lettre recommandée avec demande d&#39;avis de réception, notifiée par l&#39;une des deux
parties.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          La présente procédure de règlement amiable constitue un préalable obligatoire à l&#39;introduction
d&#39;une action en justice entre les Parties. Toute action introduite en justice en violation de la présente
clause serait déclarée irrecevable.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Toutefois, si au terme d&#39;un délai de huit jours, les Parties n&#39;arrivaient pas à se mettre d&#39;accord sur un
compromis ou une solution, le litige serait alors soumis à la compétence juridictionnelle désignée ci-
après.
        </p>
      </v-col>
      <v-col cols="10">
        <CguSubtitle number="14.2" title="attribution de compétences" />
        <p class="CGV--Text">
          Tous les litiges auxquels le présent contrat et les accords qui en découlent pourraient donner lieu,
concernant tant leur validité, leur interprétation, leur exécution, leur résolution, leurs conséquences
et leurs suites seront soumis au tribunal de commerce d’AIX -EN-PROVENCE.
        </p>
      </v-col>
      <v-col cols="10">
        <CguTitle number="15" title="langue du contrat - droit applicable" />
        <p class="CGV--Text">
          Les présentes Conditions générales de vente et les opérations qui en découlent sont régies par le
droit français.
        </p>
      </v-col>
      <v-col cols="10">
        <p class="CGV--Text">
          Elles sont rédigées en langue française. Dans le cas où elles seraient traduites en une ou plusieurs
langues, seul le texte français ferait foi en cas de litige.
        </p>
      </v-col>
      <v-col cols="10">
        <CguTitle number="16" title="acceptation du client" />
        <p class="CGV--Text">
          Les présentes Conditions Générales de Vente sont expressément agréées et acceptées par le Client,
          qui déclare et reconnaît en avoir une parfaite connaissance, et renonce, de ce fait, à se prévaloir de 
          tout document contradictoire et, notamment, ses propres conditions générales d&#39;achat, qui seront
          inopposables au Prestataire, même s&#39;il en a eu connaissance.
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import CguTitle from "../components/CguTitle.vue";
import CguSubtitle from "../components/CguSubtitle.vue";

export default {
  name: "CGV",
  components: {
    CguTitle,
    CguSubtitle,
  },
  computed: {
    ...mapGetters(["getImage"]),
  },
};
</script>

<style lang="less" scoped>
@import "../styles/CGV/cgv.less";
</style>