export default {
  menu: {
    firstSection: [
      {
        key: 1,
        icon: 'mdi-home',
        text: 'App.NavBar.Home',
        to: '/'
      },
      {
        key: 2,
        icon: 'mdi-information',
        text: 'App.NavBar.About',
        to: '/about'
      },
      {
        key: 3,
        icon: 'mdi-briefcase',
        text: 'App.NavBar.ProfessionalSpace',
        to: '/professional-space'
      }
    ],
    professionalAccess: {
      key: 4,
      icon: 'mdi-professional-hexagon',
      text: 'App.NavBar.ProfessionalAccess',
      to: '/login'
    },
    carryOutMyDiagnosis: {
      key: 5,
      icon: 'mdi-account-question',
      text: 'App.NavBar.CarryOutMyDiagnosis',
      to: '/carry-out-my-diagnosis'
    }
  }
}
