import Vue from 'vue'
import Vuex from 'vuex'
import NavBar from './NavBar'
import Login from './Login'
import Files from './Files'
import Subscriptions from './Subscriptions'
import Geocoding from './Geocoding'
import Configuration from '../config'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    imgUrl: Configuration.value('mediaUrl') + 'images/',
    toastProps: { showToast: false },
  },
  getters: {
    getImage: (state) => (name) => {
      return state.imgUrl + name
    }
  },
  mutations: {
  },
  actions: {
    showToast({ state }, props) {
      state.toastProps = { showToast: true, ...props }
    }
  },
  modules: {
    navbar: NavBar,
    login: Login,
    files: Files,
    subscriptions: Subscriptions,
    geocoding: Geocoding,
  }
})
