<template>
  <v-container fluid class="OurApproach--Container">
    <v-row justify="center" class="mt-14">
      <span class="OurApproach--Title">{{
        $t("App.About.OurApproach.Title")
      }}</span>
    </v-row>
    <v-row justify="center" class="mt-14">
      <v-col cols="12" md="10" class="OurApproach--Description">
        {{ $t("App.About.OurApproach.FirstDescription") }}
      </v-col>
      <v-col cols="12" md="10" class="OurApproach--Description">
        {{ $t("App.About.OurApproach.SecondDescription") }}
      </v-col>
      <v-col cols="12" md="5" class="OurApproach--Description">
        {{ $t("App.About.OurApproach.ThirdDescription") }}
        <v-row
          v-if="!$vuetify.breakpoint.mdAndUp"
          justify="center"
          class="my-10"
        >
          <div class="OurApproach--BtnContainer">
            <v-btn
              to="/profile"
              tile
              x-large
              color="#37A3C6"
              class="d-flex justify-center white--text"
            >
              <span>
                {{ $t("App.About.OurApproach.Buttons.DefineYourCustomerBase") }}
              </span>
            </v-btn>
          </div>
        </v-row>
      </v-col>
      <v-col cols="12" md="5" class="OurApproach--Description">
        {{ $t("App.About.OurApproach.FourthDescription") }}
        <v-row
          v-if="!$vuetify.breakpoint.mdAndUp"
          justify="center"
          class="my-10"
        >
          <div class="OurApproach--BtnContainer">
            <v-btn
              to="/profile?become-partner"
              tile
              x-large
              color="#37A3C6"
              class="d-flex justify-center white--text"
            >
              <span>
                {{ $t("App.About.OurApproach.Buttons.BecomePartner") }}
              </span>
            </v-btn>
          </div>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="$vuetify.breakpoint.mdAndUp" justify="center">
      <v-col cols="12" md="5" class="OurApproach--Description">
        <v-row justify="center" class="my-10">
          <div class="OurApproach--BtnContainer">
            <v-btn
              to="/profile"
              tile
              x-large
              color="#37A3C6"
              class="d-flex justify-center white--text"
            >
              <span>
                {{ $t("App.About.OurApproach.Buttons.DefineYourCustomerBase") }}
              </span>
            </v-btn>
          </div>
        </v-row>
      </v-col>
      <v-col cols="12" md="5" class="OurApproach--Description">
        <v-row justify="center" class="my-10">
          <div class="OurApproach--BtnContainer">
            <v-btn
              to="/profile?become-partner"
              color="#37A3C6"
              tile
              x-large
              class="d-flex justify-center white--text"
            >
              <span>
                {{ $t("App.About.OurApproach.Buttons.BecomePartner") }}
              </span>
            </v-btn>
          </div>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState } = createNamespacedHelpers("navbar");

export default {
  computed: {
    ...mapState(["menu"]),
  },
};
</script>

<style lang="less" scoped>
@import "../../styles/About/our-approach.less";
</style>
