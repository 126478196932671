export default {
  async uploadImage({ dispatch }, file) {
    const formData = new FormData()
    formData.append('image', file)
    try {
      const id = (await this._vm.$http.post('/files/image',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )).data
      return id
    } catch (e) {
      if (e.response?.status === 413) {
        dispatch('showToast', { text: 'La taille de l\'image est trop lourde' }, { root: true })
      } else {
        dispatch('showToast', {}, { root: true })
      }
    }
  }
}
