<template>
  <v-container
    fluid
    fill-height
    class="Presentation--Container"
    :style="{ backgroundImage: `url(${getImage('back-home-page.jpeg')})` }"
  >
    <v-row align="center">
      <v-col cols="12">
        <v-row
          justify="center"
          :class="{
            'pt-6': !$vuetify.breakpoint.mdAndUp,
          }"
        >
          <v-col cols="9" sm="6" md="3">
            <v-img
              alt="Logo Action Prevention"
              :src="getImage('action-prevention-logo-full.png')"
            />
          </v-col>
        </v-row>
        <v-row justify="center" class="Presentation--Description">
          <v-col cols="12" md="9">
            <p>{{ $t("App.Home.Presentation.Description") }}</p>
          </v-col>
        </v-row>
        <v-row justify="center" class="Presentation--Description">
          <v-col cols="12" sm="4" md="3">
            <div class="Presentation--Actions">
              <div>
                <v-img
                  alt="Diagnostic"
                  :src="getImage('diagnostic.png')"
                  width="60"
                />
              </div>
              <div class="mt-6">
                <span
                  class="
                    Presentation--ActionTitle
                    mt-8
                    pink--text
                    text--lighten-4
                  "
                  >{{ $t("App.Home.Presentation.Diagnostic.Title") }}</span
                >
              </div>
              <div class="mt-6">
                <span class="mt-8">{{
                  $t("App.Home.Presentation.Diagnostic.Description")
                }}</span>
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="4" md="3">
            <div class="Presentation--Actions">
              <div>
                <v-img
                  alt="Diagnostic"
                  :src="getImage('decide.png')"
                  width="60"
                />
              </div>
              <div class="mt-6">
                <span
                  class="
                    Presentation--ActionTitle
                    mt-8
                    teal--text
                    text--darken-1
                  "
                  >{{ $t("App.Home.Presentation.Decide.Title") }}</span
                >
              </div>
              <div class="mt-6">
                <span class="mt-8">{{
                  $t("App.Home.Presentation.Decide.Description")
                }}</span>
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="4" md="3">
            <div class="Presentation--Actions">
              <div>
                <v-img
                  alt="Diagnostic"
                  :src="getImage('contact.png')"
                  width="60"
                />
              </div>
              <div class="mt-6">
                <span
                  class="
                    Presentation--ActionTitle
                    mt-8
                    blue--text
                    text--lighten-2
                  "
                  >{{ $t("App.Home.Presentation.Contact.Title") }}</span
                >
              </div>
              <div class="mt-6">
                <span class="mt-8">{{
                  $t("App.Home.Presentation.Contact.Description")
                }}</span>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" md="4" lg="3">
            <v-btn
              :to="menu.carryOutMyDiagnosis.to"
              text
              tile
              x-large
              class="d-flex white light-blue--text text--accent-4"
            >
              <span>
                {{ $t(menu.carryOutMyDiagnosis.text) }}
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { mapGetters } from "vuex";

const { mapState } = createNamespacedHelpers("navbar");

export default {
  computed: {
    ...mapState(["menu"]),
    ...mapGetters(["getImage"]),
  },
};
</script>

<style lang="less" scoped>
@import "../../styles/Home/presentation.less";
</style>
