<template>
  <v-form ref="form">
    <transition name="flip">
      <div v-if="!emailSent" key="input">
        <v-text-field
          prepend-inner-icon="mdi-account"
          :label="$t('App.Login.SignIn.Email')"
          :rules="rules.email"
          v-model="email"
          outlined
          validate-on-blur
        ></v-text-field>
        <div v-if="error" class="red--text">{{ error }}</div>
      </div>
      <div v-else key="alert" style="height: 86px">
        <v-alert outlined type="success">Email envoyé !</v-alert>
      </div>
    </transition>
    <v-btn
      v-if="!emailSent"
      color="#37A3C6"
      tile
      large
      :loading="loading"
      :class="{
        'fill-width': !$vuetify.breakpoint.mdAndUp,
      }"
      @click="forgotPassword"
    >
      <span>
        {{ $t("App.Button.Valid") }}
      </span>
    </v-btn>
    <v-btn
      text
      tile
      large
      outlined
      class="light-blue--text text--accent-4"
      :class="{
        'ml-3': $vuetify.breakpoint.mdAndUp && !emailSent,
        'mt-3': !$vuetify.breakpoint.mdAndUp,
        'fill-width': !$vuetify.breakpoint.mdAndUp,
      }"
      @click="$emit('display-view', 'SignIn')"
    >
      <span>
        {{ $t("App.Button.Connection") }}
      </span>
    </v-btn>
  </v-form>
</template>

<script>
export default {
  name: "SignIn",
  data() {
    return {
      email: "",
      error: "",
      emailSent: false,
      loading: false,
      rules: {
        email: [
          (v) => !!v || "Un email est requis",
          (v) =>
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              v
            ) || "E-mail invalide",
        ],
      },
    };
  },
  methods: {
    async forgotPassword() {
      if (this.$refs.form.validate()) {
        this.error = "";
        this.loading = true;
        const isOk = await this.$store.dispatch("login/recoverPassword", {
          email: this.email,
        });
        this.loading = false;
        if (isOk) {
          this.emailSent = true;
        } else {
          this.error = this.$t("App.Errors.EmailUnknown");
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../styles/Login/sign-in.less";
</style>

