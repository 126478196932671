<template>
  <v-row no-gutters class="pt-5 BecomePartner_HowItWorks">
    <v-col cols="12">
      <p class="BecomePartner_HowItWorks--Description">
        Intégrez l’outil Action Prévention sur votre site afin de faire
        bénéficier de la puissance de l’outil à tous vos clients et récupérez un
        pourcentage des Bilans Économiques, Sociaux et Environnementaux (BESE)
        générés sur votre site.
      </p>
    </v-col>
    <v-col cols="12" class="mt-5">
      <h1 class="BecomePartner_HowItWorks--Title">Comment ça marche</h1>
    </v-col>
    <v-col cols="12" class="mt-5">
      <p class="BecomePartner_HowItWorks--Description">
        1 – Adhérez au programme de partenariat
      </p>
    </v-col>
    <v-col cols="12">
      <p class="BecomePartner_HowItWorks--Description">
        2 – Intégrez l’outil Action Prévention à votre site grâce au code reçu
        par mail
      </p>
    </v-col>
    <v-col cols="12">
      <p class="BecomePartner_HowItWorks--Description">
        3 – Récupérez 30% du montant de chaque BESE réalisé sur votre site
      </p>
    </v-col>
    <v-col cols="12" class="mt-10">
      <v-btn
        tile
        x-large
        color="#37A3C6"
        class="accent-4 white--text"
        @click="nextStep"
      >
        <span> Adhérer au programme </span>
      </v-btn>
    </v-col>
    <v-col cols="12">
      <i> Renouvellement annuel </i>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "HowItWorks",
  methods: {
    nextStep() {
      this.$emit("display-view", "IbanInfos");
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../../styles/Profile/become-partner_step-1.less";
</style>