<template>
  <v-app v-if="isDataLoaded">
    <desktop-nav-bar v-if="!isMobile" />
    <mobile-nav-bar v-if="isMobile" />
    <v-main>
      <router-view />
    </v-main>
    <Footer />
    <Toast v-bind="$store.state.toastProps" />
    <cookie-law theme="base--rounded"></cookie-law>
  </v-app>
</template>

<script>
import DesktopNavBar from "@/views/NavBar/DesktopNavBar.vue";
import MobileNavBar from "@/views/NavBar/MobileNavBar.vue";
import Footer from "@/views/Footer/Footer.vue";
import Toast from "@/components/Toast.vue";
import CookieLaw from "vue-cookie-law";

export default {
  name: "App",
  components: {
    DesktopNavBar,
    MobileNavBar,
    Footer,
    Toast,
    CookieLaw,
  },
  data: () => ({ isMobile: false, isDataLoaded: false }),
  beforeDestroy() {
    if (typeof window === "undefined") return;
    window.removeEventListener("resize", this.onResize, { passive: true });
  },
  async mounted() {
    if (this.$router.currentRoute.query.id_user) {
      localStorage.setItem(
        "id_user_coming_from",
        this.$router.currentRoute.query.id_user
      );
    } else {
      localStorage.removeItem("id_user_coming_from");
    }
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
    await this.$store.dispatch("login/fetchUser");
    this.isDataLoaded = true;
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth <= 820;
    },
  },
};
</script>

<style lang="less">
@import "./styles/app.less";
</style>
