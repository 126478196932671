export default {
  async fetchUser({ commit, state, dispatch }) {
    if (state.token) {
      try {
        const user = (await this._vm.$http.get('users/me')).data
        commit('SET_USER', user)
      } catch (e) {
        dispatch('showToast', {}, { root: true })
      }
    }
    return
  },
  async signUp({ dispatch }, user) {
    try {
      await this._vm.$http.post('auth/signup', user)
    } catch (e) {
      const message = e.response?.data?.message
      if (message === 'EMAIL_ALREADY_USED') {
        dispatch('showToast', { text: 'Email déjà utilisé' }, { root: true })
      } else if (message === 'ENTERPRISE_INACTIVE') {
        dispatch('showToast', { text: 'Votre entreprise n\'est plus en activité' }, { root: true })
      }
      return false
    }
    return true
  },
  async signIn({ commit }, payload) {
    try {
      const data = (await this._vm.$http.post('auth/signin', payload.login)).data
      if (payload.rememberMe) {
        localStorage.setItem('token', data.token)
      } else {
        localStorage.setItem('token', data.token)
      }
      commit('SET_USER', data.user)
      commit('SET_TOKEN', data.token)
    } catch (e) {
      return false
    }
    return true
  },
  async recoverPassword({ dispatch }, email) {
    try {
      await this._vm.$http.post('auth/recover', email)
    } catch (e) {
      dispatch('showToast', {}, { root: true })
      return false
    }
    return true
  },
  async resetPassword(state, payload) {
    try {
      await this._vm.$http.post('auth/reset', payload)
    } catch (e) {
      return false
    }
    return true
  },
  async saveUser({ dispatch, commit }, user) {
    try {
      const data = (await this._vm.$http.patch('users/me', user)).data
      commit('SET_USER', data)
    } catch (e) {
      const message = e.response?.data?.message
      if (message === 'EMAIL_ALREADY_USED') {
        dispatch('showToast', { text: 'Email déjà utilisé' }, { root: true })
      }
      return false
    }
    return true
  },
  logout({ commit }) {
    localStorage.removeItem("token");
    localStorage.removeItem("token");
    commit('SET_TOKEN', undefined)
    commit('login/RESET_STATE', null, { root: true })
    commit('subscriptions/RESET_STATE', null, { root: true })
  },
  async majIbanInfos({ dispatch }, payload) {
    try {
      await this._vm.$http.patch('/users/me/bank-infos', payload)
    } catch (e) {
      dispatch('showToast', {}, { root: true })
      return false
    }
    return true
  }
}
