import state from './state.js'
import actions from './actions.js'
import mutations from './mutations.js'
import getters from './getters.js'

export default {
	namespaced: true,
	state: state,
	actions: actions,
	mutations: mutations,
	getters: getters
}
