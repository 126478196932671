<template>
  <div id="home">
    <Presentation />
    <TheyTrustUs v-if="false" />
    <StartProcess v-if="false" />
  </div>
</template>

<script>
import Presentation from "@/views/Home/Presentation.vue";
import TheyTrustUs from "@/views/Home/TheyTrustUs.vue";
import StartProcess from "@/views/Home/StartProcess.vue";

export default {
  name: "Home",
  components: {
    Presentation,
    TheyTrustUs,
    StartProcess,
  },
};
</script>
