<template>
  <v-row class="UserInfos--Container">
    <v-col cols="12">
      <v-row no-gutters align="center">
        <div v-if="hasLogo" class="UserInfos--DivContainer">
          <v-img
            :src="getImage(user.enterprise.logo.path)"
            class="UserInfos--Logo"
            max-height="100"
            max-width="200"
            contain
          />
        </div>
        <span
          v-else
          class="ap-background-color white--text pa-16 text-uppercase"
        >
          {{ user.firstName[0] + user.lastName[0] }}
        </span>
      </v-row>
    </v-col>
    <v-col cols="12" class="text-truncate">
      <v-icon>mdi-map-marker</v-icon>
      {{ getInfo(user.enterprise.siege ? user.enterprise.siege.ville : "") }}
    </v-col>
    <v-col cols="12" class="text-truncate">
      <v-icon>mdi-office-building-outline</v-icon>
      {{ getInfo(user.enterprise.denomination) }}
    </v-col>
    <v-col cols="12" class="text-truncate">
      <v-icon>mdi-email-outline</v-icon> {{ getInfo(user.email) }}
    </v-col>
    <v-col cols="12">
      <v-icon>mdi-cellphone</v-icon> {{ getInfo(user.phoneNumber) }}
    </v-col>
    <v-col cols="12" class="text-truncate">
      <v-icon>mdi-web</v-icon> {{ getInfo(user.enterprise.website) }}
    </v-col>
    <v-col v-if="hasSubscription" cols="12">
      <v-btn
        tile
        class="white--text"
        color="#37A3C6"
        :loading="manageBillingLoading"
        :class="{
          'fill-width': !$vuetify.breakpoint.smAndUp,
        }"
        @click="manageBilling"
      >
        <span>
          {{ $t("App.Profile.UserInfos.BtnManageBilling") }}
        </span>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: "UserInfos",
  data() {
    return {
      manageBillingLoading: false,
    };
  },
  computed: {
    ...mapGetters(["getImage"]),
    ...mapState({
      user: (state) => state.login.user,
      subscription: (state) => state.subscriptions.subscription,
    }),
    hasLogo() {
      return !!(this.user.enterprise.logo && this.user.enterprise.logo.path);
    },
    hasSubscription() {
      return !!this.subscription.id;
    },
  },
  methods: {
    getInfo(info) {
      return info ? info : "Non renseigné";
    },
    async manageBilling() {
      this.manageBillingLoading = true;
      const url = await this.$store.dispatch("subscriptions/manageBilling");
      this.manageBillingLoading = false;
      window.location = url;
    },
  },
};
</script>
