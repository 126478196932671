<template>
  <v-row class="Header--Container" :style="{ backgroundImage: `url(${getImage('back-home-page.jpeg')})` }">
    <v-col cols="12">
      <v-row no-gutters align="center" class="fill-height">
        <v-col cols="12">
          <v-row justify="center">
            <span class="Header--FullName">
              {{
                `${$store.state.login.user.firstName} ${$store.state.login.user.lastName}`
              }}
            </span>
          </v-row>
          <v-row justify="center">
            <span class="pt-5">
              {{
                `${$store.state.login.user.enterprise.denomination}`
              }}
            </span>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: 'Header',
  computed: {
    ...mapGetters(["getImage"]),
  },
}
</script>

<style lang="less" scoped>
@import "../../styles/Profile/header.less";
</style>
