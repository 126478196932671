const getDefaultState = () => {
	return {
		user: {
			firstName: "",
			lastName: "",
			siret: "",
			phoneNumber: "",
			email: "",
			enterprise: {
				logo: {
					path: ''
				}
			}
		},
		token: localStorage.getItem('token') || localStorage.getItem('token')
	}
}

export default getDefaultState
