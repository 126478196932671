<template>
  <v-snackbar
    v-model="$store.state.toastProps.showToast"
    :color="color"
    bottom
    right
    :timeout="timeout"
    elevation="24"
  >
    {{ text }}
    <template v-slot:action="{ attrs }">
      <v-btn
        text
        v-bind="attrs"
        @click="$store.state.toastProps.showToast = false"
      >
        X
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "Une erreur est survenue",
    },
    color: {
      type: String,
      default: "red",
    },
    timeout: {
      type: String,
      default: "3000",
    },
  },
};
</script>