<template>
  <v-form ref="form" class="pt-5">
    <v-text-field
      prepend-inner-icon="mdi-account"
      :rules="rules.lastName"
      v-model="lastName"
      outlined
      validate-on-blur
    >
      <template #label>
        <span class="red--text"><strong>* </strong></span
        >{{ $t("App.Login.SignUp.LastName") }}
      </template>
    </v-text-field>
    <v-text-field
      prepend-inner-icon="mdi-account"
      :rules="rules.firstName"
      v-model="firstName"
      outlined
      validate-on-blur
    >
      <template #label>
        <span class="red--text"><strong>* </strong></span
        >{{ $t("App.Login.SignUp.FirstName") }}
      </template>
    </v-text-field>
    <v-text-field
      prepend-inner-icon="mdi-web"
      :label="$t('App.Login.SignUp.Website')"
      v-model="website"
      outlined
      validate-on-blur
    ></v-text-field>
    <v-text-field
      prepend-inner-icon="mdi-cellphone"
      :label="$t('App.Login.SignUp.PhoneNumber')"
      v-model="phoneNumber"
      type="number"
      outlined
      validate-on-blur
    ></v-text-field>
    <UploadImage ref="logo" />
    <v-text-field
      prepend-inner-icon="mdi-email-outline"
      :rules="rules.email"
      v-model="email"
      outlined
      validate-on-blur
    >
      <template #label>
        <span class="red--text"><strong>* </strong></span
        >{{ $t("App.Login.SignUp.Mail") }}
      </template>
    </v-text-field>
    <v-btn
      color="#37A3C6"
      tile
      large
      class="white--text"
      :loading="loading"
      :class="{
        'fill-width': !$vuetify.breakpoint.mdAndUp,
      }"
      @click="updateUser"
    >
      <span>
        {{ $t("App.Button.Valid") }}
      </span>
    </v-btn>
  </v-form>
</template>

<script>
import UploadImage from "@/components/UploadImage.vue";
import { mapState } from "vuex";

export default {
  name: "UpdateUserInfos",
  components: {
    UploadImage,
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      website: "",
      phoneNumber: "",
      email: "",
      confirmEmail: "",
      acceptedCgu: false,
      showPassword: false,
      loading: false,
      rules: {
        cgu: [(v) => !!v || "Vous devez valider les CGU"],
        firstName: [(v) => !!v || "Un prénom est requis"],
        lastName: [(v) => !!v || "Un nom est requis"],
        email: [
          (v) => !!v || "Un email est requis",
          (v) =>
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              v
            ) || "E-mail invalide",
        ],
      },
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.login.user,
    }),
  },
  mounted() {
    this.firstName = this.user.firstName;
    this.lastName = this.user.lastName;
    this.website = this.user.enterprise.website;
    this.phoneNumber = this.user.phoneNumber;
    this.email = this.user.email;
  },
  methods: {
    async updateUser() {
      const formValid = this.$refs.form.validate();
      if (formValid) {
        if (!this.$refs.logo.fileId && this.$refs.logo.file) {
          await this.$refs.logo.upload();
          if (!this.$refs.logo.fileId) return;
        }
        this.loading = true;
        const userSaved = await this.$store.dispatch("login/saveUser", {
          email: this.email,
          firstName: this.firstName,
          lastName: this.lastName,
          phoneNumber: this.phoneNumber,
          enterprise: {
            website: this.website,
            logo: this.$refs.logo.fileId
              ? { id: this.$refs.logo.fileId }
              : undefined,
          },
        });
        this.loading = false;
        if (userSaved) {
          this.$store.dispatch("showToast", {
            color: "green",
            text: "Modification(s) enregistrée(s)",
          });
          this.$emit("display-view", "Home");
        }
      }
    },
  },
};
</script>
