<template>
  <v-row no-gutters class="pt-5 DefineTarget">
    <v-col cols="12">
      <p class="DefineTarget--Description">
        Le service de mise en relation développé par Action Prévention permet à
        tous les professionnels de choisir ses clients grâce à des critères
        extrêmement fins et poussés.
      </p>
      <p class="DefineTarget--Description">
        Vous pourrez ainsi choisi vos clients sur un espace géographique donné,
        au regard du chiffre d’affaire généré, ou encore vis à vis de la
        procédure qui sera potentiellement ouverte à son bénéfice.
      </p>
    </v-col>
    <v-col cols="12" class="mt-5">
      <h1 class="DefineTarget--Title">Type d'étendue</h1>
    </v-col>
    <v-col cols="12" class="mt-4">
      <h4 class="DefineTarget--Question">
        Dans quelle zone géographique souhaitez-vous intervenir ?
      </h4>
    </v-col>
    <v-col cols="12" class="mt-4 pl-2">
      <v-autocomplete
        v-model="commercialCourtsSelected"
        :items="commercialCourts"
        :label="$t('App.Profile.DefineTarget.CommercialCourt')"
        :search-input.sync="searchCommercialCourt"
        item-text="greffe"
        item-value="code_greffe"
        clearable
        outlined
        chips
        multiple
      >
        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-title> Aucune correspondance </v-list-item-title>
          </v-list-item>
        </template>
        <template v-slot:selection="data">
          <v-chip
            v-bind="data.attrs"
            :input-value="data.selected"
            close
            color="#37A3C6"
            class="white--text"
            @click="data.select"
            @click:close="removeCommercialCourt(data.item)"
          >
            {{ data.item.greffe }}
          </v-chip>
        </template>
      </v-autocomplete>
    </v-col>
    <v-col cols="12" class="mt-4 pl-2">
      <v-autocomplete
        v-model="legalCourtsSelected"
        :items="legalCourts"
        :label="$t('App.Profile.DefineTarget.LegalCourt')"
        :search-input.sync="searchLegalCourt"
        item-text="tj"
        item-value="tj_code"
        clearable
        outlined
        chips
        multiple
      >
        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-title> Aucune correspondance </v-list-item-title>
          </v-list-item>
        </template>
        <template v-slot:selection="data">
          <v-chip
            v-bind="data.attrs"
            :input-value="data.selected"
            close
            color="#37A3C6"
            class="white--text"
            @click="data.select"
            @click:close="removeLegalCourt(data.item)"
          >
            {{ data.item.tj }}
          </v-chip>
        </template>
      </v-autocomplete>
    </v-col>
    <v-col cols="12" class="mt-5">
      <h1 class="DefineTarget--Title">Procédures ciblées</h1>
    </v-col>
    <v-col cols="12" class="mt-4">
      <h4 class="DefineTarget--Question">
        Pour quelles procédures souhaitez-vous intervenir ?
      </h4>
    </v-col>
    <v-col cols="12" class="mt-4 pl-2">
      <v-checkbox
        color="#37A3C6"
        v-model="procedures"
        label="Mandat ad hoc"
        :value="procedureEnum.AD_HOC"
        hide-details
      ></v-checkbox>
      <v-checkbox
        color="#37A3C6"
        v-model="procedures"
        label="Conciliation"
        :value="procedureEnum.CONCILIATION"
        hide-details
      ></v-checkbox>
      <v-checkbox
        color="#37A3C6"
        v-model="procedures"
        label="Règlement amiable"
        :value="procedureEnum.AMICAL_SETTLEMENT"
        hide-details
      ></v-checkbox>
      <v-checkbox
        color="#37A3C6"
        v-model="procedures"
        label="Sauvegarde"
        :value="procedureEnum.SAVE"
        hide-details
      ></v-checkbox>
      <v-checkbox
        color="#37A3C6"
        v-model="procedures"
        label="Redressement judiciaire"
        :value="procedureEnum.LEGAL_REDRESS"
        hide-details
      ></v-checkbox>
      <v-checkbox
        color="#37A3C6"
        v-model="procedures"
        label="Liquidation judiciaire"
        :value="procedureEnum.JUDICIAL_LIQUIDATION"
        hide-details
      ></v-checkbox>
    </v-col>
    <v-col cols="12" class="mt-12">
      <h1 class="DefineTarget--Title">CA annuel</h1>
    </v-col>
    <v-col cols="12" class="mt-4">
      <h4 class="DefineTarget--Question">
        Cochez les tranches de CA (en k€) des entreprises que vous souhaitez
        accompagner :
      </h4>
    </v-col>
    <v-col cols="12" class="mt-4 pl-2">
      <v-checkbox
        color="#37A3C6"
        v-model="turnoverRanges"
        label="Moins de 100"
        :value="turnoverRangeEnum.LESS_HUNDRED"
        hide-details
      ></v-checkbox>
      <v-checkbox
        color="#37A3C6"
        v-model="turnoverRanges"
        label="100 - 250"
        :value="turnoverRangeEnum.HUNDRED_TO_TWO_HUNDRED_AND_HALF"
        hide-details
      ></v-checkbox>
      <v-checkbox
        color="#37A3C6"
        v-model="turnoverRanges"
        label="250 - 500"
        :value="turnoverRangeEnum.TWO_HUNDRED_AND_HALF_TO_FIVE_HUNDRED"
        hide-details
      ></v-checkbox>
      <v-checkbox
        color="#37A3C6"
        v-model="turnoverRanges"
        label="500 - 1000"
        :value="turnoverRangeEnum.FIVE_HUNDRED_TO_ONE_THOUSAND"
        hide-details
      ></v-checkbox>
      <v-checkbox
        color="#37A3C6"
        v-model="turnoverRanges"
        label="1000 - 5000"
        :value="turnoverRangeEnum.ONE_THOUSAND_TO_FIVE_THOUSAND"
        hide-details
      ></v-checkbox>
      <v-checkbox
        color="#37A3C6"
        v-model="turnoverRanges"
        label="Plus de 5000"
        :value="turnoverRangeEnum.MORE_THAN_FIVE_THOUSAND"
        hide-details
      ></v-checkbox>
    </v-col>
    <v-col cols="12" class="mt-12">
      <h2>Votre abonnement mensuel</h2>
    </v-col>
    <v-col cols="12" class="mt-10">
      <span class="DefineTarget--SubscriptionPrice">
        {{ monthlySubscriptionPrice }}€
      </span>
    </v-col>
    <v-col cols="12">
      <span> Prix HT </span>
    </v-col>
    <v-col cols="12" class="mt-10">
      <v-checkbox
        v-if="!hasSubscription"
        color="#37A3C6"
        v-model="cgvAccepted"
        class="DefineTarget--Checkbox"
        hide-details
      >
        <template v-slot:[`label`]>
          <span class="DefineTarget--Retract"
            >En validant l'abonnement j'accepte les
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <a
                  :href="websiteUrl + '/cgv'"
                  target="_blank"
                  @click.stop
                  v-on="on"
                  >CGV</a
                >
              </template>
              S'ouvre dans une nouvelle fenêtre
            </v-tooltip>
            et je renonce expressément à mon droit de rétractation de 14
            jours.</span
          >
        </template>
      </v-checkbox>
      <v-btn
        color="#37A3C6"
        tile
        x-large
        class="white--text"
        :class="{
          'fill-width': !$vuetify.breakpoint.mdAndUp,
        }"
        :outlined="!cgvAccepted"
        :disabled="
          (!hasSubscription && monthlySubscriptionPrice === 0) ||
          (hasSubscription && !hasSubscriptionBeenModified) ||
          !cgvAccepted
        "
        :loading="majLoading"
        @click="updateSubscription"
      >
        <span v-if="!hasSubscription"> Valider mon abonnement </span>
        <span v-else> Mettre à jour mon abonnement </span>
      </v-btn>
      <v-btn
        v-if="hasSubscription && !isSubscriptionSuspended"
        tile
        x-large
        class="accent-4 red white--text"
        :class="{
          'ml-3': $vuetify.breakpoint.lgAndUp,
          'mt-3': $vuetify.breakpoint.mdAndDown,
          'fill-width': !$vuetify.breakpoint.mdAndUp,
        }"
        :loading="cancelLoading"
        @click="showSuspendModal = true"
      >
        <span>Annuler mon abonnement</span>
      </v-btn>
      <v-btn
        v-if="hasSubscription && isSubscriptionSuspended"
        tile
        x-large
        class="accent-4 green white--text"
        :class="{
          'ml-3': $vuetify.breakpoint.lgAndUp,
          'mt-3': $vuetify.breakpoint.mdAndDown,
          'fill-width': !$vuetify.breakpoint.mdAndUp,
        }"
        :loading="reactivateLoading"
        @click="reactivateSubscription"
      >
        <span>Réactiver mon abonnement</span>
      </v-btn>
    </v-col>
    <v-col cols="12" class="mt-1">
      <span class="red--text" v-if="isSubscriptionSuspended">
        Votre abonnement prendra fin le
        {{ subscription.cancel_at | formatDate }}</span
      >
    </v-col>
    <v-col cols="12" class="mt-1">
      <i>
        Vous pouvez à tout moment venir modifier la configuration de votre
        ciblage et adapter votre forfait mensuel.
      </i>
    </v-col>
    <v-dialog v-model="showModal" hide-overlay width="300">
      <v-card>
        <SuccessAnimation
          v-if="!subscriptionError"
          title="Validé"
          :subTitle="successSubtitle"
        />
        <ErrorAnimation
          v-else
          title="Annulation"
          subTitle="Le paiement a été annulé !"
        />
      </v-card>
    </v-dialog>
    <v-dialog v-model="showSuspendModal" width="400">
      <v-card>
        <v-card-title class="text-h5 DefineTarget--ModalTitle">
          Voulez-vous vraiment annuler votre abonnement ?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :loading="cancelLoading"
            color="red darken-1"
            text
            @click="suspendSubscription"
          >
            Suspendre
          </v-btn>
          <v-btn color="darken-1" text @click="showSuspendModal = false">
            Retour
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import dayjs from "dayjs";
import { mapState } from "vuex";
import { find, isEqual, differenceBy, omitBy, sortBy } from "lodash";
import SuccessAnimation from "@/components/SuccessAnimation";
import ErrorAnimation from "@/components/ErrorAnimation";
import ProcedurePrices from "@/enums/ProcedurePrices.enum";
import TurnoverRangePrices from "@/enums/TurnoverRangePrices.enum";
import CourtPrices from "@/enums/CourtPrices.enum";
import commercialCourtsData from "@/utils/lst-greffes.json";
import legalCourtsData from "@/utils/lst-legal-courts.json";

export default {
  name: "DefineTarget",
  components: {
    SuccessAnimation,
    ErrorAnimation,
  },
  data() {
    return {
      procedureEnum: ProcedurePrices,
      turnoverRangeEnum: TurnoverRangePrices,
      courtEnum: CourtPrices,
      turnoverRanges: [],
      procedures: [],
      monthlySubscriptionPrice: 0,
      prices: [],
      showModal: false,
      showSuspendModal: false,
      subscriptionError: false,
      majLoading: false,
      cancelLoading: false,
      reactivateLoading: false,
      cgvAccepted: false,
      legalCourtsSelected: [],
      legalCourts: sortBy(legalCourtsData, (x) => x.tj),
      searchLegalCourt: "",
      commercialCourtsSelected: [],
      commercialCourts: sortBy(commercialCourtsData, (x) => x.greffe),
      searchCommercialCourt: "",
      websiteUrl: window.location.origin,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.login.user,
      subscription: (state) => state.subscriptions.subscription,
      partnershipSubscription: (state) =>
        state.subscriptions.partnershipSubscription,
    }),
    hasSubscriptionBeenModified() {
      return (
        !isEqual(
          this.prices.map((x) => x.price).sort(),
          this.subscription.items.data.map((x) => x.price.id).sort()
        ) ||
        !isEqual(
          this.commercialCourtsSelected.map((x) => x).sort(), // .map(x => x) to fix infinity loop
          this.subscription.commercialCourts.map((x) => x).sort()
        ) ||
        !isEqual(
          this.legalCourtsSelected.map((x) => x).sort(),
          this.subscription.legalCourts.map((x) => x).sort()
        )
      );
    },
    hasSubscription() {
      return !!this.subscription.id;
    },
    successSubtitle() {
      return !this.hasSubscription
        ? "Abonnement validé !"
        : "Abonnement mis à jour !";
    },
    isSubscriptionSuspended() {
      return this.subscription.cancel_at_period_end;
    },
  },
  watch: {
    turnoverRanges() {
      this.calculateMonthlySubscriptionPrice();
    },
    procedures() {
      this.calculateMonthlySubscriptionPrice();
    },
    commercialCourtsSelected() {
      this.calculateMonthlySubscriptionPrice();
      this.searchCommercialCourt = "";
    },
    legalCourtsSelected() {
      this.calculateMonthlySubscriptionPrice();
      this.searchLegalCourt = "";
    },
    subscription() {
      if (this.subscription.items.data) {
        this.turnoverRanges = [];
        this.procedures = [];
        this.subscription.items.data.forEach((subItem) => {
          if (subItem.price.id === this.turnoverRangeEnum.ALL) {
            this.turnoverRanges = Object.keys(this.turnoverRangeEnum)
              .filter((x) => x !== "ALL")
              .map((x) => this.turnoverRangeEnum[x]);
          } else if (subItem.price.id === this.procedureEnum.ALL) {
            this.procedures = Object.keys(this.procedureEnum)
              .filter((x) => x !== "ALL")
              .map((x) => this.procedureEnum[x]);
          } else if (
            find(this.turnoverRangeEnum, (x) => x === subItem.price.id)
          ) {
            this.turnoverRanges.push(subItem.price.id);
          } else if (find(this.procedureEnum, (x) => x === subItem.price.id)) {
            this.procedures.push(subItem.price.id);
          }
        });
      }
      if (this.subscription.commercialCourts) {
        this.commercialCourtsSelected = this.subscription.commercialCourts;
      }
      if (this.subscription.legalCourts) {
        this.legalCourtsSelected = this.subscription.legalCourts;
      }
    },
  },
  filters: {
    formatDate: function (date) {
      return dayjs.unix(date).format("DD/MM/YYYY");
    },
  },
  async mounted() {
    if (this.user.stripeCustomerId) {
      await this.$store.dispatch("subscriptions/fetchSubscriptions");
    }
    if (
      this.$router.currentRoute.query.success ||
      this.$router.currentRoute.query.canceled
    ) {
      this.showModal = true;
      this.subscriptionError = !!this.$router.currentRoute.query.canceled;
      var newRouteQuery = omitBy(this.$route.query, (val, key) =>
        ["success", "canceled"].includes(key)
      );
      this.$router.replace({ query: newRouteQuery });
    }
  },
  methods: {
    async suspendSubscription() {
      this.cancelLoading = true;
      await this.$store.dispatch(
        "subscriptions/suspendSubscription",
        this.subscription.id
      );
      this.cancelLoading = false;
      this.showSuspendModal = false;
    },
    async reactivateSubscription() {
      this.reactivateLoading = true;
      await this.$store.dispatch(
        "subscriptions/reactivateSubscription",
        this.partnershipSubscription.id
      );
      this.reactivateLoading = false;
    },
    async updateSubscription() {
      if (this.hasSubscription) {
        const basePrices = this.subscription.items.data.map((x) => {
          return { id: x.id, price: x.price.id, quantity: x.quantity };
        });

        const pricesToRemove = differenceBy(
          basePrices,
          this.prices,
          (x) => x.price
        );
        const pricesToAdd = differenceBy(
          this.prices,
          basePrices,
          (x) => x.price
        );

        pricesToRemove.forEach((x) => {
          x["deleted"] = true;
        });

        let finalPrices = pricesToAdd.concat(pricesToRemove);
        let isReqOk;
        if (this.prices.length === 0) {
          this.cancelLoading = true;
          isReqOk = await this.$store.dispatch(
            "subscriptions/suspendSubscription",
            this.subscription.id
          );
          this.cancelLoading = false;
        } else {
          this.majLoading = true;
          isReqOk = await this.$store.dispatch(
            "subscriptions/updateSubscription",
            {
              subscriptionId: this.subscription.id,
              commercialCourts: this.commercialCourtsSelected,
              legalCourts: this.legalCourtsSelected,
              prices: finalPrices,
            }
          );
          this.majLoading = false;
        }

        if (isReqOk) {
          this.showModal = true;
        } else {
          await this.$store.dispatch("subscriptions/fetchSubscriptions");
        }
      } else if (this.user.stripeCustomerId) {
        this.majLoading = true;
        const isReqOk = await this.$store.dispatch(
          "subscriptions/addSubscription",
          {
            commercialCourts: this.commercialCourtsSelected,
            legalCourts: this.legalCourtsSelected,
            prices: this.prices,
          }
        );
        this.majLoading = false;

        if (isReqOk) {
          this.showModal = true;
        } else {
          await this.$store.dispatch("subscriptions/fetchSubscriptions");
        }
      } else {
        this.majLoading = true;
        const url = await this.$store.dispatch(
          "subscriptions/createSubscription",
          {
            commercialCourts: this.commercialCourtsSelected,
            legalCourts: this.legalCourtsSelected,
            prices: this.prices,
          }
        );
        this.majLoading = false;
        window.location = url;
      }
    },
    calculateMonthlySubscriptionPrice() {
      this.prices = [];
      let newPrice = 0;

      //#region TurnoverRanges
      if (this.turnoverRanges.length === 6) {
        this.prices.push({ price: this.turnoverRangeEnum.ALL, quantity: 1 });
        newPrice += 150;
      } else {
        this.turnoverRanges.forEach((x) => {
          this.prices.push({ price: x, quantity: 1 });
          switch (x) {
            case this.turnoverRangeEnum.LESS_HUNDRED:
              newPrice += 8;
              break;
            case this.turnoverRangeEnum.HUNDRED_TO_TWO_HUNDRED_AND_HALF:
              newPrice += 12;
              break;
            case this.turnoverRangeEnum.TWO_HUNDRED_AND_HALF_TO_FIVE_HUNDRED:
              newPrice += 20;
              break;
            case this.turnoverRangeEnum.FIVE_HUNDRED_TO_ONE_THOUSAND:
              newPrice += 32;
              break;
            case this.turnoverRangeEnum.ONE_THOUSAND_TO_FIVE_THOUSAND:
              newPrice += 50;
              break;
            case this.turnoverRangeEnum.MORE_THAN_FIVE_THOUSAND:
              newPrice += 80;
              break;
          }
        });
      }
      //#endregion

      //#region Procedures
      if (this.procedures.length === 6) {
        this.prices.push({ price: this.procedureEnum.ALL, quantity: 1 });
        newPrice += 50;
      } else {
        this.procedures.forEach((x) => {
          this.prices.push({ price: x, quantity: 1 });
          switch (x) {
            case this.procedureEnum.SAVE:
            case this.procedureEnum.LEGAL_REDRESS:
            case this.procedureEnum.JUDICIAL_LIQUIDATION:
              newPrice += 10;
              break;
            case this.procedureEnum.CONCILIATION:
            case this.procedureEnum.AD_HOC:
              newPrice += 15;
              break;
            case this.procedureEnum.AMICAL_SETTLEMENT:
              newPrice += 0;
              break;
          }
        });
      }
      //#endregion

      //#region Localisations
      let nbCourtSelected =
        this.commercialCourtsSelected.length + this.legalCourtsSelected.length;
      if (nbCourtSelected > 0) {
        if (nbCourtSelected > 8) nbCourtSelected = 8;
        this.prices.push({
          price: this.courtEnum[nbCourtSelected].price,
          quantity: 1,
        });
        newPrice += this.courtEnum[nbCourtSelected].up;
      }

      //#endregion

      if (this.prices.length > 0) {
        this.prices.push({
          price: "price_1LITrkBNojRG2LLAB63lTUKo",
          quantity: 1,
        });
        newPrice += 50;
      }

      this.monthlySubscriptionPrice = newPrice;

      return this.prices;
    },
    removeCommercialCourt(item) {
      this.commercialCourtsSelected = this.commercialCourtsSelected.filter(
        (x) => x !== item.code_greffe
      );
    },
    removeLegalCourt(item) {
      this.legalCourtsSelected = this.legalCourtsSelected.filter(
        (x) => x !== item.tj_code
      );
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../styles/Profile/define-target.less";
</style>

