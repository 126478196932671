<template>
  <v-app-bar elevation="0" color="white">
    <div class="d-flex align-center">
      <router-link to="/">
        <v-img
          alt="Logo Action Prevention"
          :src="getImage('logo.png')"
          width="50"
        />
      </router-link>
    </div>

    <router-link
      v-for="navLink in menu.firstSection"
      :to="navLink.to"
      :key="navLink.text"
      exact
      class="NavLink"
    >
      {{ $t(navLink.text) }}
    </router-link>

    <v-spacer></v-spacer>
    <router-link
      v-if="!loggedIn"
      :to="menu.professionalAccess.to"
      exact
      class="NavLink"
    >
      {{ $t(menu.professionalAccess.text) }}
    </router-link>
    <AvatarMenu v-else />

    <v-btn
      @click="goToDiag"
      exact
      tile
      text
      outlined
      active-class="no-active"
      class="light-blue--text text--accent-4"
    >
      <span>
        {{ $t(menu.carryOutMyDiagnosis.text) }}
      </span>
    </v-btn>
  </v-app-bar>
</template>

<script>
import AvatarMenu from "./AvatarMenu.vue";
import { createNamespacedHelpers } from "vuex";
import { mapGetters } from "vuex";

const { mapState } = createNamespacedHelpers("navbar");

export default {
  components: {
    AvatarMenu,
  },
  computed: {
    ...mapGetters({
      getImage: "getImage",
      loggedIn: "login/loggedIn",
    }),
    ...mapState(["menu"]),
    hasLogo() {
      return (
        this.$store.state.login.user.logo &&
        this.$store.state.login.user.logo.path
      );
    },
  },
  methods: {
    goToDiag() {
      window.location = '/carry-out-my-diagnosis'
    }
  }
};
</script>