<template>
  <div class="relative">
    <v-file-input
      v-model="file"
      :rules="rules"
      :loading="loading"
      :disabled="loading"
      outlined
      label="Logo"
      prepend-inner-icon="mdi-camera"
      prepend-icon=""
      accept="image/png, image/jpeg, image/svg"
      @change="reset"
    >
      <template v-slot:selection="{ text }">
        <v-chip small label color="primary">
          {{ text }}
        </v-chip>
      </template>
    </v-file-input>
  </div>
</template>

<script>
export default {
  name: "UploadImage",
  props: {
    fileName: {
      type: String,
      default: "",
    },
    submitFile: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      file: undefined,
      fileId: undefined,
      loading: false,
      rules: [
        (value) =>
          !value ||
          value.size < 1000000 ||
          "La taille du logo devrait être inférieur à 1 MB",
      ],
    };
  },
  methods: {
    async upload() {
      if (this.fileId) return;
      try {
        this.loading = true;
        this.fileId = await this.$store.dispatch(
          "files/uploadImage",
          this.file
        );
      } catch (error) {
        this.$store.dispatch("showToast", {
          text: "Une erreur s'est produite lors du téléchargement du logo",
        });
      } finally {
        this.loading = false;
      }
    },
    reset() {
      this.fileId = undefined;
    },
  },
};
</script>
<style>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}
</style>
