<template>
  <v-container fluid class="StartProcess--Container">
    <v-row justify="center" class="mt-14">
      <v-col cols="12" sm="9">
        <h2>{{ $t("App.Home.StartProcess.Title") }}</h2>
      </v-col>
      <v-col cols="12" sm="9">
        <div class="TitleUnderline ap-background-color"></div>
      </v-col>
    </v-row>
    <v-row justify="center" class="my-10">
      <v-col cols="12" sm="9">
        <div class="StartProcess--BtnContainer">
          <v-btn
            :to="menu.carryOutMyDiagnosis.to"
            color="#37A3C6"
            tile
            x-large
            class="d-flex justify-center white--text"
          >
            <span>
              {{ $t(menu.carryOutMyDiagnosis.text) }}
            </span>
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState } = createNamespacedHelpers("navbar");

export default {
  computed: {
    ...mapState(["menu"]),
  },
};
</script>

<style lang="less" scoped>
@import "../../styles/Home/start-process.less";
</style>
