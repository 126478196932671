import PartnershipPrice from "@/enums/PartnershipPrice.enum";
import { head } from 'lodash/core'

export default {
  async createSubscription({ dispatch }, payload) {
    try {
      const url = (await this._vm.$http.post("/subscriptions/create-checkout-session", payload)).data;
      return url
    } catch (e) {
      dispatch('showToast', {}, { root: true })
    }
  },
  async addSubscription({ dispatch }, payload) {
    try {
      await this._vm.$http.post("/subscriptions", payload);
    } catch (e) {
      dispatch('showToast', {}, { root: true })
      return false
    }
    return true
  },
  async updateSubscription({ dispatch, commit }, payload) {
    try {
      const subscription = (await this._vm.$http.patch('/subscriptions/' + payload.subscriptionId, payload)).data
      commit('SET_SUBSCRIPTION', subscription)
    } catch (e) {
      dispatch('showToast', {}, { root: true })
      return false
    }
    return true
  },
  async suspendSubscription({ dispatch, commit }, subscriptionId) {
    try {
      const sub = (await this._vm.$http.patch('/subscriptions/' + subscriptionId + '/suspend')).data
      head(sub.items.data)?.price.id === PartnershipPrice.Partnership ? commit('SET_PARTNERSHIP_SUBSCRIPTION', sub) : commit('SET_SUBSCRIPTION', sub)
    } catch (e) {
      dispatch('showToast', {}, { root: true })
      return false
    }
    return true
  },
  async reactivateSubscription({ dispatch, commit }, subscriptionId) {
    try {
      const sub = (await this._vm.$http.patch('/subscriptions/' + subscriptionId + '/activate')).data
      head(sub.items.data)?.price.id === PartnershipPrice.Partnership ? commit('SET_PARTNERSHIP_SUBSCRIPTION', sub) : commit('SET_SUBSCRIPTION', sub)
    } catch (e) {
      dispatch('showToast', {}, { root: true })
      return false
    }
    return true
  },
  async fetchSubscriptions({ commit, dispatch }) {
    try {
      const subscriptions = (await this._vm.$http.get('subscriptions/my-subscriptions')).data
      const sub = subscriptions.find(x => head(x.items.data)?.price.id !== PartnershipPrice.Partnership)
      sub ? commit('SET_SUBSCRIPTION', sub) : null
      const partSub = subscriptions.find(x => head(x.items.data)?.price.id === PartnershipPrice.Partnership)
      partSub ? commit('SET_PARTNERSHIP_SUBSCRIPTION', partSub) : null
      return subscriptions
    } catch (e) {
      const message = e.response?.data?.message
      if (!['STRIPE_USER_NOT_FOUND', 'STRIPE_USER_SUBSCRIPTION_NOT_FOUND'].includes(message)) {
        dispatch('showToast', {}, { root: true })
      }
    }
  },
  async manageBilling({ dispatch }) {
    try {
      const url = (await this._vm.$http.post("/subscriptions/create-portal-session")).data;
      return url
    } catch (e) {
      dispatch('showToast', {}, { root: true })
    }
  }
}
