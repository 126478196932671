<template>
  <v-form ref="form" class="SignUp">
    <v-text-field
      prepend-inner-icon="mdi-account"
      :rules="rules.lastName"
      v-model="lastName"
      outlined
      validate-on-blur
    >
      <template #label>
        <span class="red--text"><strong>* </strong></span
        >{{ $t("App.Login.SignUp.LastName") }}
      </template>
    </v-text-field>
    <v-text-field
      prepend-inner-icon="mdi-account"
      :rules="rules.firstName"
      v-model="firstName"
      outlined
      validate-on-blur
    >
      <template #label>
        <span class="red--text"><strong>* </strong></span
        >{{ $t("App.Login.SignUp.FirstName") }}
      </template>
    </v-text-field>
    <v-select
      prepend-inner-icon="mdi-briefcase-outline"
      v-model="occupation"
      :rules="rules.occupation"
      :items="occupationItems"
      outlined
    >
      <template #label>
        <span class="red--text"><strong>* </strong></span>Fonction
      </template>
    </v-select>
    <v-autocomplete
      v-if="!typeInManually"
      v-model="enterprise"
      :items="itemsSiret"
      :loading="isLoadingSiret"
      :search-input.sync="searchSiret"
      :rules="rules.enterprise"
      :filter="onFilter"
      item-text="denomination"
      item-value="siren"
      prepend-inner-icon="mdi-office-building-outline"
      clearable
      outlined
      return-object
    >
      <template #label>
        <span class="red--text">
          <strong>* </strong>
        </span>
        {{ $t("App.Login.SignUp.Enterprise") }}
      </template>
      >
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-title> Rechercher par nom </v-list-item-title>
        </v-list-item>
      </template>
      <template v-slot:item="{ item }">
        <v-list-item-content>
          <v-list-item-title v-text="item.denomination"></v-list-item-title>
          <v-list-item-subtitle
            v-text="
              itemsSiret.filter((x) => x.denomination === item.denomination)
                .length > 1
                ? `${item.siege.siret} - ${item.villes ? item.villes[0] : ''}`
                : item.siege.siret
            "
          ></v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-autocomplete>
    <v-checkbox v-model="typeInManually" label="Saisir manuellement les infos d'entreprise" class="mt-0"></v-checkbox>
    <div v-if="typeInManually">
      <v-text-field
        prepend-inner-icon="mdi-office-building-outline"
        v-model="siret"
        :rules="rules.siret"
        outlined
        validate-on-blur
      >
      <template #label>
        <span class="red--text">
          <strong>* </strong>
        </span>
        SIRET
      </template>
    </v-text-field>
      <v-text-field
        prepend-inner-icon="mdi-office-building-outline"
        v-model="denomination"
        :rules="rules.denomination"
        outlined
        validate-on-blur
      >
      <template #label>
        <span class="red--text">
          <strong>* </strong>
        </span>
        Dénomination
      </template>
    </v-text-field>
    <v-autocomplete
      v-model="coordinates"
      :items="itemsAdresse"
      :loading="isLoadingAdresse"
      :search-input.sync="searchAdresse"
      :rules="rules.coordinates"
      :filter="onFilterAdresse"
      item-text="label"
      item-value="id"
      prepend-inner-icon="mdi-office-building-outline"
      clearable
      outlined
      return-object
    >
      <template #label>
        <span class="red--text">
          <strong>* </strong>
        </span>
        Adresse
      </template>
      >
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-title> Rechercher par adresse </v-list-item-title>
        </v-list-item>
      </template>
      <template v-slot:item="{ item }">
        <v-list-item-content>
          <v-list-item-title v-text="item.label"></v-list-item-title>
        </v-list-item-content>
      </template>
    </v-autocomplete>
    </div>
    <v-text-field
      prepend-inner-icon="mdi-web"
      :label="$t('App.Login.SignUp.Website')"
      v-model="website"
      outlined
      validate-on-blur
    ></v-text-field>
    <v-text-field
      prepend-inner-icon="mdi-cellphone"
      :label="$t('App.Login.SignUp.PhoneNumber')"
      v-model="phoneNumber"
      type="number"
      outlined
      validate-on-blur
    ></v-text-field>
    <UploadImage ref="logo" />
    <v-form ref="emailForm">
      <v-text-field
        prepend-inner-icon="mdi-email-outline"
        :rules="[...rules.email, emailsMatch]"
        v-model="email"
        outlined
        validate-on-blur
        @blur="!!confirmEmail && $refs.emailForm.validate()"
      >
        <template #label>
          <span class="red--text"><strong>* </strong></span
          >{{ $t("App.Login.SignUp.Mail") }}
        </template>
      </v-text-field>
      <v-text-field
        prepend-inner-icon="mdi-email-outline"
        :rules="[...rules.email, emailsMatch]"
        v-model="confirmEmail"
        outlined
        validate-on-blur
        @blur="!!email && $refs.emailForm.validate()"
      >
        <template #label>
          <span class="red--text"><strong>* </strong></span
          >{{ $t("App.Login.SignUp.ConfirmMail") }}
        </template>
      </v-text-field>
    </v-form>
    <v-form ref="passwordForm">
      <v-text-field
        prepend-inner-icon="mdi-lock-outline"
        :type="showPassword ? 'text' : 'password'"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="[...rules.password, passwordsMatch]"
        v-model="password"
        outlined
        validate-on-blur
        @blur="!!confirmPassword && $refs.passwordForm.validate()"
        @click:append="showPassword = !showPassword"
      >
        <template #label>
          <span class="red--text"><strong>* </strong></span
          >{{ $t("App.Login.SignUp.Password") }}
        </template>
      </v-text-field>
      <v-text-field
        prepend-inner-icon="mdi-lock-outline"
        :type="showPassword ? 'text' : 'password'"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="[...rules.password, passwordsMatch]"
        v-model="confirmPassword"
        outlined
        validate-on-blur
        @blur="!!password && $refs.passwordForm.validate()"
        @click:append="showPassword = !showPassword"
      >
        <template #label>
          <span class="red--text">
            <strong>* </strong>
          </span>
          {{ $t("App.Login.SignUp.ConfirmPassword") }}
        </template>
      </v-text-field>
    </v-form>
    <v-checkbox v-model="acceptedCgu" :rules="rules.cgu" required>
      <template #label>
        <span class="red--text">
          <strong>* </strong>
        </span>
        <span> J'accepte les <router-link to="/cgu">CGU</router-link> </span>
      </template>
    </v-checkbox>
    <v-btn
      color="#37A3C6"
      tile
      large
      class="white--text"
      :loading="loading"
      :class="{
        'fill-width': !$vuetify.breakpoint.mdAndUp,
      }"
      @click="signUp"
    >
      <span>
        {{ $t("App.Button.SignUp") }}
      </span>
    </v-btn>
    <v-btn
      text
      tile
      outlined
      large
      class="light-blue--text text--accent-4"
      :class="{
        'ml-3': $vuetify.breakpoint.mdAndUp,
        'mt-3': !$vuetify.breakpoint.mdAndUp,
        'fill-width': !$vuetify.breakpoint.mdAndUp,
      }"
      @click="$emit('display-view', 'SignIn')"
    >
      <span>
        {{ $t("App.Button.SignIn") }}
      </span>
    </v-btn>
  </v-form>
</template>

<script>
import UploadImage from "@/components/UploadImage.vue";
import Occupation from "@/enums/Occupation.enum";

export default {
  name: "SignUp",
  components: {
    UploadImage,
  },
  watch: {
    async searchSiret(val) {
      if (!val) return;
      this.isLoadingSiret = true;
      this.itemsSiret = (
        await this.$http.get(
          "https://suggestions.pappers.fr/v2?q=" + val + "&cibles=denomination"
        )
      ).data.resultats_denomination;
      this.isLoadingSiret = false;
    },
    async searchAdresse(val) {
      if (!val || val.length < 5) return;
      this.isLoadingAdresse = true;
      this.itemsAdresse = await this.$store.dispatch("geocoding/search", val);
      this.isLoadingAdresse = false;
    },
  },
  data() {
    return {
      typeInManually: false,
      isLoadingSiret: false,
      isLoadingAdresse: false,
      modelSiret: null,
      searchSiret: null,
      searchAdresse: null,
      itemsSiret: [],
      itemsAdresse: [],
      denomination: "",
      coordinates: null,
      firstName: "",
      lastName: "",
      occupation: null,
      enterprise: null,
      siret: "",
      website: "",
      phoneNumber: "",
      email: "",
      confirmEmail: "",
      password: "",
      confirmPassword: "",
      acceptedCgu: false,
      occupationItems: [
        {
          text: "Mandataire judiciaire",
          value: Occupation.MANDATAIRE_JUDICIAIRE,
        },
        {
          text: "Avocat",
          value: Occupation.AVOCAT,
        },
        {
          text: "Administrateur judiciaire",
          value: Occupation.ADMINISTRATEUR_JUDICIARE,
        },
        {
          text: "Expert comptable",
          value: Occupation.EXPERT_COMPTABLE,
        },
      ],
      showPassword: false,
      loading: false,
      rules: {
        cgu: [(v) => !!v || "Vous devez valider les CGU"],
        firstName: [(v) => !!v || "Un prénom est requis"],
        lastName: [(v) => !!v || "Un nom est requis"],
        enterprise: [(v) => !!v || "Une entreprise est requise"],
        siret: [(v) => !!v || "Un SIRET est requis"],
        occupation: [(v) => !!v || v === 0 || "Une fonction est requise"],
        denomination: [(v) => !!v || "Une denomination est requise"],
        coordinates: [(v) => !!v || "Une adresse est requise"],
        email: [
          (v) => !!v || "Un email est requis",
          (v) =>
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              v
            ) || "E-mail invalide",
        ],
        password: [
          (v) => !!v || "Un mot de passe est requis",
          (v) =>
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%&*])(?=.{6,})/.test(
              v
            ) ||
            "Min. 6 caractères avec au moins une capitale, un nombre et un caractère spécial",
        ],
      },
    };
  },
  computed: {
    passwordsMatch() {
      return () =>
        !this.password ||
        !this.confirmPassword ||
        this.password === this.confirmPassword ||
        "Les mots de passe ne correspondent pas";
    },
    emailsMatch() {
      return () =>
        !this.email ||
        !this.confirmEmail ||
        this.email === this.confirmEmail ||
        "Les emails ne correspondent pas";
    },
  },
  methods: {
    async signUp() {
      const passwordValid = this.$refs.passwordForm.validate();
      const emailValid = this.$refs.emailForm.validate();
      const formValid = this.$refs.form.validate();
      if (formValid && emailValid && passwordValid) {
        if (!this.$refs.logo.fileId && this.$refs.logo.file) {
          await this.$refs.logo.upload();
          if (!this.$refs.logo.fileId) return;
        }
        this.loading = true;
        const signedUp = await this.$store.dispatch("login/signUp", {
          firstName: this.firstName,
          lastName: this.lastName,
          occupation: this.occupation,
          siret: this.enterprise ? this.enterprise.siege.siret : this.siret,
          denomination: this.denomination,
          coordinates: this.coordinates,
          website: this.website,
          phoneNumber: this.phoneNumber,
          logo: this.$refs.logo.fileId ? { id: this.$refs.logo.fileId } : null,
          email: this.email,
          password: this.password,
          acceptedCgu: this.acceptedCgu,
          typeInManually: this.typeInManually,
        });
        this.loading = false;
        if (signedUp) {
          this.$emit("display-view", "SignIn");
        }
      }
    },
    onFilter(item, queryText) {
      return item.nom_entreprise
        .toLocaleLowerCase()
        .includes(queryText.trim().toLocaleLowerCase());
    },
    onFilterAdresse() {
      return true;
    },
  },
};
</script>
