<template>
  <div id="logout">Déconnexion...</div>
</template>

<script>
export default {
  name: "Logout",
  created() {
    this.$store.dispatch("login/logout");
    this.$router.push("/login");
  },
};
</script>
