<template>
  <v-card class="mx-auto" @click="openUrl">
    <v-img
      class="white--text align-end"
      :src="getImage(photo)"
    >
    </v-img>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TeamCard",
  computed: {
    ...mapGetters(["getImage"]),
  },
  props: {
    url: {
      type: String,
      required: true,
    },
    photo: {
      type: String,
      required: true,
    },
  },
  methods: {
    openUrl() {
      window.open(this.url, '_blank').focus()
    }
  }
};
</script>