import Vue from 'vue'
import VueScrollTo from 'vue-scrollto'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import router from './router'
import store from './store'
import { HTTP } from './http-common';
import './plugins/leaflet'
import VueMask from 'v-mask'

Vue.prototype.$http = HTTP
Vue.config.productionTip = false
Vue.config.ignoredElements = [
  'action-prevention-diagnosis'
]

Vue.use(VueMask)
Vue.use(VueScrollTo)

new Vue({
  vuetify,
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
