import axios from 'axios';
import Configuration from './config'
import store from './store'

const axiosInst = axios.create({
  baseURL: Configuration.value('apiUrl') || 'http://localhost:3002/api'
})

axiosInst.interceptors.request.use(
  config => {
    if (!config.url.includes('suggestions.pappers.fr')) {
      config.headers['Authorization'] = `Bearer ${store.state.login.token}`
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
)

export const HTTP = axiosInst
