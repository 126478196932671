export default {
  1: {
    price: "price_1LITsCBNojRG2LLAhCUlfaHe",
    up: 0,
  },
  2: {
    price: "price_1LITs7BNojRG2LLAFoWFYtEG",
    up: 8,
  },
  3: {
    price: "price_1LITs3BNojRG2LLAnTBKGkbx",
    up: 15,
  },
  4: {
    price: "price_1LITrvBNojRG2LLA9OwdCFSC",
    up: 22,
  },
  5: {
    price: "price_1LITrrBNojRG2LLAb2OTjKeI",
    up: 36,
  },
  6: {
    price: "price_1LITrTBNojRG2LLA9rxpQtWb",
    up: 45,
  },
  7: {
    price: "price_1LITrcBNojRG2LLADeZBnuNY",
    up: 55,
  },
  8: {
    price: "price_1LITrYBNojRG2LLAHKpLAkYk",
    up: 75,
  },
}