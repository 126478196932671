<template>
  <div>
    <transition name="flip">
      <div v-if="!passwordChanged" key="input">
        <v-form ref="passwordForm">
          <v-text-field
            prepend-inner-icon="mdi-lock-outline"
            :label="$t('App.Login.SignUp.Password')"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[...rules.password, passwordsMatch]"
            v-model="password"
            outlined
            validate-on-blur
            @blur="!!confirmPassword && $refs.passwordForm.validate()"
            @click:append="showPassword = !showPassword"
          >
            <template #label>
              <span class="red--text"><strong>* </strong></span
              >{{ $t("App.Login.SignUp.Password") }}
            </template>
          </v-text-field>
          <v-text-field
            prepend-inner-icon="mdi-lock-outline"
            :label="$t('App.Login.SignUp.ConfirmPassword')"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[...rules.password, passwordsMatch]"
            v-model="confirmPassword"
            outlined
            validate-on-blur
            @blur="!!password && $refs.passwordForm.validate()"
            @click:append="showPassword = !showPassword"
          >
            <template #label>
              <span class="red--text"><strong>* </strong></span
              >{{ $t("App.Login.SignUp.ConfirmPassword") }}
            </template>
          </v-text-field>
        </v-form>
        <div v-if="error" class="red--text">{{ error }}</div>
      </div>
      <div v-else key="alert" style="height: 86px">
        <v-alert outlined type="success">Mot de passe modifié !</v-alert>
      </div>
    </transition>
    <v-btn
      v-if="passwordChanged"
      color="#37A3C6"
      tile
      large
      outlined
      class="white--text"
      :class="{
        'ml-3': $vuetify.breakpoint.mdAndUp,
        'mt-3': !$vuetify.breakpoint.mdAndUp,
        'fill-width': !$vuetify.breakpoint.mdAndUp,
      }"
      @click="redirectToConnection"
    >
      <span>
        {{ $t("App.Button.Connection") }}
      </span>
    </v-btn>
    <v-btn
      v-if="!passwordChanged"
      color="#37A3C6"
      tile
      large
      outlined
      class="white--text"
      :loading="loading"
      :class="{
        'ml-3': $vuetify.breakpoint.mdAndUp,
        'mt-3': !$vuetify.breakpoint.mdAndUp,
        'fill-width': !$vuetify.breakpoint.mdAndUp,
      }"
      @click="recoverPassword"
    >
      <span>
        {{ $t("App.Button.Valid") }}
      </span>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "RecoverPassword",
  data() {
    return {
      password: "",
      confirmPassword: "",
      showPassword: false,
      passwordChanged: false,
      loading: false,
      error: "",
      rules: {
        password: [
          (v) => !!v || "Un mot de passe est requis",
          (v) =>
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%&*])(?=.{6,})/.test(
              v
            ) ||
            "Min. 6 caractères avec au moins une capitale, un nombre et un caractère spécial",
        ],
      },
    };
  },
  computed: {
    passwordsMatch() {
      return () =>
        !this.password ||
        !this.confirmPassword ||
        this.password === this.confirmPassword ||
        "Les mots de passe ne correspondent pas";
    },
  },
  methods: {
    async recoverPassword() {
      if (this.$refs.passwordForm.validate()) {
        this.error = "";
        this.loading = true;
        const isOk = await this.$store.dispatch("login/resetPassword", {
          token: this.$route.params.token,
          password: this.password,
        });
        this.loading = false;
        if (isOk) {
          this.passwordChanged = true;
        } else {
          this.error = this.$t("App.Errors.TokenOutdated");
        }
      }
    },
    redirectToConnection() {
      this.$router.push("/login");
      this.$emit("display-view", "SignIn");
    },
  },
};
</script>

