<template>
  <v-container fluid class="pa-0">
    <v-row no-gutters>
      <v-container
        fluid
        class="CGU--Container"
        :style="{ backgroundImage: `url(${getImage('back-home-page.jpeg')})` }"
      >
        <v-row justify="center">
          <span class="CGU--Title">Condition générales d'utilisation</span>
        </v-row>
      </v-container>
    </v-row>
    <v-row justify="center">
      <v-col cols="10">
        <CguTitle number="1" title="objet" />
        <p class="CGU--Text">
          Les présentes conditions générales d’utilisation (ci-après « <b>CGU</b> ») ont pour objet l’encadrement
          juridique des modalités de mise à disposition du site et des services par ACTION PRÉVENTION et de
          définir les conditions d’accès et d’utilisation des services par « <b>l'Utilisateur</b> ».
      </p>
      </v-col>
      <v-col cols="10">
        <p class="CGU--Text">
          Toute inscription ou utilisation du site implique l&#39;acceptation sans aucune réserve ni restriction des
          présentes CGU par l’Utilisateur. Lors de l&#39;inscription sur le site via le Formulaire d’inscription, chaque
          utilisateur accepte expressément les présentes CGU en cochant la case précédant le texte suivant :
      </p>
      </v-col>
      <v-col cols="10">
        <p class="CGU--Text"><b>« Je reconnais avoir lu et compris les CGU et je les accepte ».</b></p>
      </v-col>
      <v-col cols="10">
        <p class="CGU--Text">Les présentes CGU sont accessibles sur le site à la rubrique « CGU ».</p>
      </v-col>
      <v-col cols="10">
        <p class="CGU--Text">Action Prévention se réserve le droit de modifier unilatéralement et à tout moment le contenu des
présentes CGU.
      </p>
      </v-col>
      <v-col cols="10">
        <CguTitle number="2" title="les mentions légales" />
        <p class="CGU--Text">L’édition du site ACTION PRÉVENTION (<u>https://action-prevention.com</u>) est assurée par l’association
ACTION PRÉVENTION, immatriculée sous le numéro W131017514, dont le siège social est situé au 19
bis Cours des Arts et Métiers, 13100 AIX-EN-PROVENCE ; 07 71 54 38 80 ; <u>contact@action-prevention.com</u>.
      </p>
      </v-col>
      <v-col cols="10">
        <p class="CGU--Text">N° de TVA intracommunautaire : FR85914838420</p>
      </v-col>
      <v-col cols="10">
        <p class="CGU--Text">Le Directeur de la publication est Gaëtan DELMAS.</p>
      </v-col>
      <v-col cols="10">
        <p class="CGU--Text">L’hébergeur du site ACTION PRÉVENTION est la société OVH SAS qui est une filiale de la société OVH Groupe SA, société immatriculée au RCS de Lille sous le numéro 537 407 926 sise 2, rue Kellermann, 59100 Roubaix.
      </p>
      </v-col>
      <v-col cols="10">
        <CguTitle number="3" title="accès au site" />
        <p class="CGU--Text">Le site est accessible gratuitement en tout lieu à tout Utilisateur ayant un accès à Internet. Tous les
frais supportés par l&#39;Utilisateur pour accéder au service (matériel informatique, logiciels, connexion
Internet, etc.) sont à sa charge.
      </p>
      </v-col>
      <v-col cols="10">
        <p class="CGU--Text">Le site ACTION PRÉVENTION permet à l&#39;Utilisateur non-membre un accès gratuit aux services suivants :
        <ul>
          <li>Le diagnostic des difficultés de son entreprise</li>
          <li>La mise en relation avec les professionnels les plus proches de lui</li>
          <li>L’accès à la présentation de la société (À propos de nous), à la présentation de l’offre pour
              les professionnels (Espace professionnel), au blog ainsi qu’au lexique.</li>
        </ul>
      </p>
      </v-col>
      <v-col cols="10">
        <p class="CGU--Text">L’Utilisateur non-membre n&#39;a pas accès aux services réservés (Accès professionnels). Pour cela, il doit
s’inscrire en remplissant le formulaire.
      </p>
      </v-col>
      <v-col cols="10">
        <p class="CGU--Text">Le site ACTION PRÉVENTION permet à l&#39;Utilisateur membre un accès supplémentaire, en contrepartie
d’un paiement ou d’un abonnement, aux services suivants :
        <ul>
          <li>La sélection des clients grâce à des critères extrêmement fins et poussés</li>
          <li>La pré-rédaction d’actes juridiques</li>
          <li>L’apparition sur les sites de nos partenaires</li>
          <li>L’augmentation naturelle de votre trafic en cas d’option pour la marque blanche</li>
        </ul>
      </p>
      </v-col>
      <v-col cols="10">
        <p class="CGU--Text">En acceptant de s’inscrire aux services réservés, l’Utilisateur membre s’engage à fournir des
informations sincères et exactes concernant son état civil et ses coordonnées, notamment son
adresse email.
      </p>
      </v-col>
      <v-col cols="10">
        <p class="CGU--Text">L’Utilisateur est responsable de la mise à jour des informations fournies. Il lui est précisé́ qu’il peut
les modifier en se connectant à son espace membre.
      </p>
      </v-col>
      <v-col cols="10">
        <p class="CGU--Text">Pour accéder aux services, l’Utilisateur devra s&#39;identifier à l&#39;aide de son nom d’utilisateur et de son
mot de passe qui sont strictement personnels. A ce titre, il s’en interdit toute divulgation. Dans le cas
contraire, il restera seul responsable de l’usage qui en sera fait.
      </p>
      </v-col>
      <v-col cols="10">
        <p class="CGU--Text">L’Utilisateur pourra également solliciter sa désinscription en se rendant à la page dédiée sur son
espace personnel ou envoyant un email à : <u>contact@action-prevention.com</u>
      </p>
      </v-col>
      <v-col cols="10">
        <p class="CGU--Text">En cas de non-respect des conditions générales de vente et d’utilisation, le site ACTION PRÉVENTION
aura la possibilité́ de suspendre et/ou de fermer le compte de l’Utilisateur après mise en demeure
adressée par voie électronique et restée sans effet.
      </p>
      </v-col>
      <v-col cols="10">
        <p class="CGU--Text">Toute suppression de compte, quel qu’en soit le motif, engendre la suppression pure et simple de
toutes informations personnelles de l’Utilisateur.
      </p>
      </v-col>
      <v-col cols="10">
        <p class="CGU--Text">Tout évènement dû à un cas de force majeure ayant pour conséquence un dysfonctionnement du
site ou serveur et sous réserve de toute interruption ou modification en cas de maintenance,
n&#39;engage pas la responsabilité́ d’ACTION PRÉVENTION. Dans ces cas, l’Utilisateur accepte ainsi ne pas
tenir rigueur à l’éditeur de toute interruption ou suspension de service, même sans préavis.
      </p>
      </v-col>
      <v-col cols="10">
        <p class="CGU--Text">L&#39;Utilisateur a la possibilité de contacter le site par messagerie électronique à l’adresse email de
l’éditeur communiqué à l’ARTICLE 2.
      </p>
      </v-col>
      <v-col cols="10">
        <CguTitle number="4" title="collecte des données" />
        <p class="CGU--Text">VOIR POLITIQUE DE CONFIDENTIALITÉ</p>
      </v-col>
      <v-col cols="10">
        <CguTitle number="5" title="propriété intellectuelle" />
        <p class="CGU--Text">Les marques, logos, signes ainsi que tous les contenus du site (textes, images, son…) font l’objet
d’une protection par le Code de la propriété intellectuelle et plus particulièrement par le droit
d’auteur.
      </p>
      </v-col>
      <v-col cols="10">
        <p class="CGU--Text">L’Utilisateur doit solliciter l’autorisation préalable du site pour toute reproduction, publication, copie
des différents contenus. Il s’engage à une utilisation des contenus du site dans un cadre strictement
privé, toute utilisation à des fins commerciales et publicitaires est strictement interdite.
      </p>
      </v-col>
      <v-col cols="10">
        <p class="CGU--Text">Toute représentation totale ou partielle de ce site par quelque procédé que ce soit, sans
l’autorisation expresse de l’exploitant du site Internet constituerait une contrefaçon sanctionnée par
l’article L 335-2 et suivants du Code de la propriété intellectuelle.
      </p>
      </v-col>
      <v-col cols="10">
        <p class="CGU--Text">Il est rappelé, conformément à l’article L 122-5 du Code de la propriété intellectuelle, que
l’Utilisateur qui reproduit, copie ou publie le contenu protégé doit citer l’auteur et sa source.
      </p>
      </v-col>
      <v-col cols="10">
        <CguTitle number="6" title="responsabilité" />
        <p class="CGU--Text">Les sources des informations diffusées sur le site <u>https://action-prevention.com</u> sont réputées fiables
mais le site ne garantit pas qu’il soit exempt de défauts, d’erreurs ou d’omissions.
      </p>
      </v-col>
      <v-col cols="10">
        <p class="CGU--Text">Les informations communiquées sont présentées à titre indicatif et général sans valeur contractuelle
et sans valeur de consultation juridique.
      </p>
      </v-col>
      <v-col cols="10">
        <p class="CGU--Text">Malgré des mises à jour régulières, le site ACTION PRÉVENTION ne peut être tenu responsable de la
modification des dispositions administratives et juridiques survenant après la publication. De même,
le site ne peut être tenu responsable de l’utilisation et de l’interprétation de l’information contenue
dans ce site.
      </p>
      </v-col>
      <v-col cols="10">
        <p class="CGU--Text">L&#39;Utilisateur membre s&#39;assure de garder son mot de passe secret. Toute divulgation du mot de passe,
quelle que soit sa forme, est interdite. Il assume les risques liés à l&#39;utilisation de son identifiant et
mot de passe. Le site décline toute responsabilité.
      </p>
      </v-col>
      <v-col cols="10">
        <p class="CGU--Text">Le site ACTION PRÉVENTION ne peut être tenu pour responsable d’éventuels virus qui pourraient
infecter l’ordinateur ou tout matériel informatique de l’Internaute, suite à une utilisation, à l’accès,
ou au téléchargement provenant de ce site.
      </p>
      </v-col>
      <v-col cols="10">
        <p class="CGU--Text">La responsabilité du site ne peut être engagée en cas de force majeure ou du fait imprévisible et
insurmontable d’un tiers.
      </p>
      </v-col>
      <v-col cols="10">
        <CguTitle number="7" title="liens hypertextes" />
        <p class="CGU--Text">Des liens hypertextes peuvent être présents sur le site. L’Utilisateur est informé qu’en cliquant sur
ces liens, il sortira du site ACTION PRÉVENTION. Ce dernier n’a pas de contrôle sur les pages web sur
lesquelles aboutissent ces liens et ne saurait, en aucun cas, être responsable de leur contenu.
      </p>
      </v-col>
      <v-col cols="10">
        <CguTitle number="8" title="cookies" />
        <p class="CGU--Text">VOIR POLITIQUE DE CONFIDENTIALITÉ</p>
      </v-col>
      <v-col cols="10">
        <CguTitle number="9" title="droit applicable et juridiction compétente" />
        <p class="CGU--Text">La législation française s’applique au présent contrat. En cas d’absence de résolution amiable d’un
litige entre les parties, les tribunaux français seront seuls compétents pour en connaitre.
      </p>
      </v-col>
      <v-col cols="10">
        <p class="CGU--Text">Pour toute question relative à l’application des présentes CGU, vous pouvez joindre l’éditeur aux
coordonnées inscrites à l’ARTICLE 2.
      </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import CguTitle from "../components/CguTitle.vue";

export default {
  name: "CGU",
  components: {
    CguTitle,
  },
  computed: {
    ...mapGetters(["getImage"]),
  },
};
</script>

<style lang="less" scoped>
@import "../styles/CGU/cgu.less";
</style>