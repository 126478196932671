<template>
  <v-container
    fluid
    fill-height
    class="Presentation--Container"
    :style="{ backgroundImage: `url(${getImage('back-home-page.jpeg')})` }"
  >
    <v-row justify="center">
      <v-col cols="12" md="9">
        <v-row
          :class="{
            'Presentation--Logo': $vuetify.breakpoint.mdAndUp,
            'pt-6': !$vuetify.breakpoint.mdAndUp,
          }"
        >
          <v-col cols="9" sm="6" md="3">
            <v-img
              alt="Logo Action Prevention"
              :src="getImage('action-prevention-logo-full.png')"
            />
          </v-col>
        </v-row>
        <v-row class="Presentation--Title">
          <v-col cols="12">
            <p>{{ $t("App.ProfessionalSpace.Presentation.Title") }}</p>
          </v-col>
        </v-row>
        <v-row class="Presentation--Description">
          <v-col cols="12" sm="4">
            <div class="Presentation--Actions">
              <div>
                <v-img
                  alt="Diagnostic"
                  :src="getImage('contact.png')"
                  width="60"
                  v-scroll-to="'#putInTouch'"
                  class="pointer"
                />
              </div>
              <div class="mt-6">
                <span class="Presentation--ActionTitle mt-8">
                  {{
                    $t(
                      "App.ProfessionalSpace.Presentation.PutPeopleInTouch.Title"
                    )
                  }}
                </span>
              </div>
              <div class="mt-6">
                <span class="mt-8">
                  {{
                    $t(
                      "App.ProfessionalSpace.Presentation.PutPeopleInTouch.Description"
                    )
                  }}
                </span>
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="4">
            <div class="Presentation--Actions">
              <div>
                <v-img
                  alt="Diagnostic"
                  :src="getImage('edit-bese.png')"
                  width="60"
                  v-scroll-to="'#editBalanceSheet'"
                  class="pointer"
                />
              </div>
              <div class="mt-6">
                <span class="Presentation--ActionTitle mt-8">
                  {{
                    $t("App.ProfessionalSpace.Presentation.BeseEdition.Title")
                  }}
                </span>
              </div>
              <div class="mt-6">
                <span class="mt-8">
                  {{
                    $t(
                      "App.ProfessionalSpace.Presentation.BeseEdition.Description"
                    )
                  }}
                </span>
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="4">
            <div class="Presentation--Actions">
              <div>
                <v-img
                  alt="Diagnostic"
                  :src="getImage('white-mark.png')"
                  width="60"
                  v-scroll-to="'#whiteMark'"
                  class="pointer"
                />
              </div>
              <div class="mt-6">
                <span class="Presentation--ActionTitle mt-8">
                  {{ $t("App.ProfessionalSpace.Presentation.WhiteMark.Title") }}
                </span>
              </div>
              <div class="mt-6">
                <span class="mt-8">
                  {{
                    $t(
                      "App.ProfessionalSpace.Presentation.WhiteMark.Description"
                    )
                  }}
                </span>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row class="my-10">
          <v-col cols="12">
            <v-row no-gutters justify="center">
              <v-btn
                to="/login"
                text
                tile
                class="white light-blue--text text--accent-4 float-left"
                :class="{
                  'fill-width': !$vuetify.breakpoint.smAndUp,
                }"
              >
                <span>
                  {{ $t("App.Button.SignIn") }}
                </span>
              </v-btn>
              <v-btn
                to="/login"
                text
                tile
                class="white light-blue--text text--accent-4"
                :class="{
                  'ml-3': $vuetify.breakpoint.smAndUp,
                  'mt-3': !$vuetify.breakpoint.smAndUp,
                  'fill-width': !$vuetify.breakpoint.smAndUp,
                }"
              >
                <span>
                  {{ $t("App.Button.SignUp") }}
                </span>
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["getImage"]),
  },
};
</script>

<style lang="less" scoped>
@import "../../styles/ProfessionalSpace/presentation.less";
</style>
