<template>
  <v-container fluid class="pa-0">
    <v-row no-gutters>
      <v-container
        fluid
        class="PrivacyPolicy--Container"
        :style="{ backgroundImage: `url(${getImage('back-home-page.jpeg')})` }"
      >
        <v-row justify="center">
          <span class="PrivacyPolicy--Title">Politique de confidentialité</span>
        </v-row>
      </v-container>
    </v-row>
    <v-row justify="center">
      <v-col cols="10">
        <CguTitle number="1" title="objet" />
        <p class="PrivacyPolicy--Text">
          La présente politique de confidentialité des données a pour objet d’informer les utilisateurs des
engagements et mesures pris par ACTION PRÉVENTION afin de respecter la réglementation inhérente
à la protection des données (ci-après la « Politique de Confidentialité »).
      </p>
      </v-col>
      <v-col cols="10">
        <p class="PrivacyPolicy--Text">
          La Politique de Confidentialité définit et vous informe de la manière dont ACTION PRÉVENTION
utilise et protège les informations que vous nous transmettez lorsque vous utilisez le Site.
      </p>
      </v-col>
      <v-col cols="10">
        <p class="PrivacyPolicy--Text">
          En sus des articles du présent engagement, ACTION PRÉVENTION s’engage à respecter dans leur
généralité les clauses du L 119/1 RÈGLEMENT (UE) 2016/679 DU PARLEMENT EUROPÉEN ET DU
CONSEIL du 27 avril 2016 relatif à la protection des personnes physiques à l’égard du traitement des
données à caractère personnel et à la libre circulation de ces données, et abrogeant la directive
95/46/CE (règlement général sur la protection des données) (ci-après « RGPD »).
      </p>
      </v-col>
      <v-col cols="10">
        <p class="PrivacyPolicy--Text">
          La présente Politique de Confidentialité est susceptible d’être modifiée ou complétée à tout moment
par ACTION PRÉVENTION, notamment en vue de se conformer à toute évolution législative,
réglementaire, jurisprudentielle ou technologique.
      </p>
      </v-col>
      <v-col cols="10">
        <p class="PrivacyPolicy--Text">
          Ces modifications engagent l’Utilisateur dès la mise en ligne. Il convient par conséquent que
l’Utilisateur consulte régulièrement la présente politique de confidentialité afin de prendre
connaissance de ses éventuelles modifications.
      </p>
      </v-col>
      <v-col cols="10">
        <CguTitle number="2" title="contact délégué à la protection des données" />
        <p class="PrivacyPolicy--Text">
          Le responsable du traitement des données, appelé Délégué à la Protection des Données (DPO) est
          Monsieur Gaëtan DELMAS.
          <ul>
            <li>Mail : gd@action-prevention.com</li>
            <li>Tél : +33 (0) 7 71 54 38 80</li>
          </ul>
      </p>
      </v-col>
      <v-col cols="10">
        <p class="PrivacyPolicy--Text">
          Pour toute demande d’information concernant la politique de protection des données personnelles,
          l’Utilisateur peut adresser un courrier au délégué à la protection des données d’ACTION PRÉVENTION
          à l’adresse électronique suivante : gd@action-prevention.com.
      </p>
      </v-col>
      <v-col cols="10">
        <CguTitle number="3" title="données personnelles" />
        <p class="PrivacyPolicy--Text">
          D’une manière générale, il vous est possible de visiter le Site ACTION PRÉVENTION sans
          communiquer aucune information personnelle vous concernant. En toute hypothèse, vous êtes en
          aucune manière obligé de transmettre ces informations à ACTION PRÉVENTION.
      </p>
      </v-col>
      <v-col cols="10">
        <p class="PrivacyPolicy--Text">
          Néanmoins, en cas de refus, il se peut que vous ne puissiez pas bénéficier de certaines informations
ou services que vous avez demandé.
      </p>
      </v-col>
      <v-col cols="10">
        <p class="PrivacyPolicy--Text">
          Le site assure à l’Utilisateur une collecte et un traitement d’informations personnelles dans le respect
de la vie privée conformément à la loi n°78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers
et aux libertés.
      </p>
      </v-col>
      <v-col cols="10">
        <p class="PrivacyPolicy--Text">
          Les données personnelles de l’Utilisateur qui sont collectées directement (lors du diagnostic) ou
indirectement (les données de connexions) répondent à des finalités explicites, légitimes et
déterminées.
      </p>
      </v-col>
      <v-col cols="10">
        <p class="PrivacyPolicy--Text">
          Elles sont utilisées par ACTION PRÉVENTION :
          <ul>
            <li>Soit à des fins statistiques</li>
            <li>Soit pour enrichir le contenu éditorial et l’adapter aux besoins des Utilisateurs</li>
            <li>Soit pour répondre aux sollicitations et aux demandes d’informations sur les services offerts par ACTION PRÉVENTION</li>
            <li>Soit pour la gestion l’abonnement des Utilisateurs aux publications du blog</li>
          </ul>
      </p>
      </v-col>
      <v-col cols="10">
        <p class="PrivacyPolicy--Text">
          La communication des données personnelles dans ce cadre est volontaire. Si l’Utilisateur ne fournit
pas ces données personnelles, il ne pourra pas bénéficier des services correspondants par le Site
ACTION PRÉVENTION tels la communication, le téléchargement de documents ou le recrutement.
      </p>
      </v-col>
      <v-col cols="10">
        <CguTitle number="4" title="droits des utilisateurs" />
        <p class="PrivacyPolicy--Text">
          En vertu de la loi Informatique et Libertés, en date du 6 janvier 1978, l’Utilisateur dispose
notamment d’un droit d’accès, de rectification et modification, de limitation et de suppression.
      </p>
      </v-col>
      <v-col cols="10">
        <p class="PrivacyPolicy--Text">
          Il dispose également d’un droit d’opposition au traitement de ses données personnelles pour des
motifs légitimes, ainsi que d’un droit d’opposition à ce que ses données soient utilisées à des fins de
prospection commerciale.
      </p>
      </v-col>
      <v-col cols="10">
        <p class="PrivacyPolicy--Text">
          Il dispose enfin du droit de définir des directives générales et particulières définissant la manière
dont il entend que soient exercés, après son décès, ces droits.
      </p>
      </v-col>
      <v-col cols="10">
        <p class="PrivacyPolicy--Text">
          L’utilisateur dispose, en tout état de cause, du droit d’introduire une réclamation auprès de la CNIL.
      </p>
      </v-col>
      <v-col cols="10">
        <p class="PrivacyPolicy--Text">
          Pour exercer ses droits, l’utilisateur doit adresser un courrier au Délégué à la Protection des Données
d’ACTION PRÉVENTION, accompagné de la photocopie d’un titre d’identité comportant sa signature,
à l’adresse de courrier électronique <u>gd@action-prevention.com</u>.
      </p>
      </v-col>
      <v-col cols="10">
        <CguTitle number="5" title="conservation des données" />
        <p class="PrivacyPolicy--Text">
          Vos informations personnelles sont conservées par ACTION PRÉVENTION pour le temps
correspondant à la finalité de la collecte. Ce temps doit être compris pour toute la durée de la
relation contractuelle établie entre ACTION PRÉVENTION et l’Utilisateur, augmenté de 3 ans à des
fins d’animation et de prospection, sans préjudice des obligations de conservation ou des délais de
prescription.
      </p>
      </v-col>
      <v-col cols="10">
        <p class="PrivacyPolicy--Text">
          Toutefois, au terme du délai, ACTION PRÉVENTION se réserve le droit de conserver dans ses archives
les données au sujet desquelles ACTION PRÉVENTION est soumis à des obligations légales de
conservation et/ou d’archivage prévues par le droit de l’Union Européenne ou le droit d’un État
membre. L’accès aux archives sera réservé aux seules personnes habilitées par ACTION PRÉVENTION.
      </p>
      </v-col>
      <v-col cols="10">
        <CguTitle number="6" title="sécurité" />
        <p class="PrivacyPolicy--Text">
          Le Site est fourni « tel quel » et « comme disponible » sans garantie quelle qu’elle soit. L’accès et
l’utilisation du Site ACTION PRÉVENTION se font aux risques et périls de l’Utilisateur.
      </p>
      </v-col>
      <v-col cols="10">
        <p class="PrivacyPolicy--Text">
          <u>Risques pour l’Utilisateur :</u>
          Il appartient à l’Utilisateur de prendre toutes les mesures appropriées de façon à protéger ses
propres données et/ou logiciels stockés sur son et/ou ses matériels téléphonique et/ou informatique
contre toute atteinte, dysfonctionnement, virus, piratage, etc… (liste non exhaustive).
      </p>
      </v-col>
      <v-col cols="10">
        <p class="PrivacyPolicy--Text">
          En utilisant le Site, l’Utilisateur assume toutes les conséquences négatives que pourraient engendrer le Site.
      </p>
      </v-col>
      <v-col cols="10">
        <p class="PrivacyPolicy--Text">
          <u>Risques sur le Réseau Internet :</u>
          Les performances techniques de l’Internet mobile requièrent un temps de traitement nécessaire
pour répondre, consulter, interroger ou transférer les services.
      </p>
      </v-col>
      <v-col cols="10">
        <p class="PrivacyPolicy--Text">
          L’Utilisateur déclare connaître et accepter les limites et les problématiques du réseau Internet pour
          lesquelles la responsabilité d’ACTION PRÉVENTION ne saurait être engagée (liste non-exhaustive) :
          <ul>
            <li>Les caractéristiques et les limites du réseau Internet et, notamment les caractéristiques fonctionnelles et des performances techniques du réseau Internet</li>
            <li>Les problèmes liés à la connexion et/ ou d’accès au réseau Internet et/ou aux sites web</li>
            <li>Les problèmes liés à la disponibilité et à l’encombrement des réseaux</li>
            <li>Les problèmes liés à la défaillance ou à la saturation des réseaux</li>
            <li>Les problèmes liés aux temps de transit, à l’accès aux informations mises en ligne, aux temps de réponses pour afficher, consulter, interroger ou autrement transférer des données</li>
            <li>Les risques d’interruption</li>
            <li>L’absence de protection de certaines données contre des détournements éventuels ou piratage</li>
            <li>Les risques de contamination par d’éventuels virus circulant sur lesdits réseaux</li>
          </ul>
      </p>
      </v-col>
      <v-col cols="10">
        <CguTitle number="7" title="cookies" />
        <p class="PrivacyPolicy--Text">
          Lors de votre première connexion sur le Site ACTION PRÉVENTION, vous êtes avertis par un bandeau
en bas de votre écran que des informations relatives sont susceptibles d’être enregistrées dans des
fichiers dénommées « cookies » :
      </p>
      </v-col>
      <v-col cols="10">
        <p class="PrivacyPolicy--Text">
          La présente politique d’utilisation des cookies vous informe sur les dispositions que nous mettons en
œuvre en matière de navigation web. Nous vous invitons à lire attentivement le présent document
pour connaître et comprendre nos pratiques quant aux traitements de vos données personnelles que
nous mettons en œuvre.
      </p>
      </v-col>
      <v-col cols="10">
        <p class="PrivacyPolicy--Text">
          <u>Informations générales sur les cookies :</u>
          ACTION PRÉVENTION, en tant qu’éditeur du présent site Web, pourra procéder à l’implantation d’un
« cookie » sur le disque dur de votre terminal afin de vous garantir une navigation fluide et optimale
sur notre Site.
      </p>
      </v-col>
      <v-col cols="10">
        <p class="PrivacyPolicy--Text">
          Les « cookies » sont des petits fichiers texte de taille limitée qui nous permettent de reconnaître
votre ordinateur, votre tablette ou votre mobile aux fins de personnaliser les services que nous vous
proposons (contenu, annonces, fonctionnalités, etc…).
      </p>
      </v-col>
      <v-col cols="10">
        <p class="PrivacyPolicy--Text">
          Les « cookies » ne permettent pas de vous identifier, ils sont utilisés dans l’unique but d’améliorer
l’interactivité et la performance de notre Site et de vous adresser un contenu adapté. Ces
informations ne font pas l’objet d’une communication auprès de tiers sauf lorsqu’ACTION
PRÉVENTION a obtenu votre consentement préalable ou lorsque la divulgation de ces informations
est requise par la loi, sur ordre d’une juridiction ou toute autre autorité administrative ou judiciaire
habilitée à en connaitre.
      </p>
      </v-col>
      <v-col cols="10">
        <p class="PrivacyPolicy--Text">
          <u>Cookies de réseaux sociaux :</u>
          Les cookies de partage des réseaux sociaux sont émis et gérés par l’éditeur du réseau social
concerné.
      </p>
      </v-col>
      <v-col cols="10">
        <p class="PrivacyPolicy--Text">
          Sous réserve de votre consentement, ces cookies vous permettent de partager facilement une partie
du contenu publié sur le Site d’ACTION PRÉVENTION, notamment par l’intermédiaire d’un bouton
applicatif de partage selon le réseau concerné.
      </p>
      </v-col>
      <v-col cols="10">
        <p class="PrivacyPolicy--Text">
          Trois types de cookies de partage des réseaux sociaux sont présents sur le Site :
          <ul>
            <li>Facebook, dont vous pouvez consulter la politique en matière de cookies en cliquant sur le lien suivant : https://fr-fr.facebook.com/policies/cookies/</li>
            <li>Linkedin, dont vous pouvez consulter la politique relative aux cookies en cliquant sur le lien suivant : https://www.linkedin.com/legal/cookie-policy?_l=fr_FR</li>
            <li>Twitter, dont vous pouvez consulter la politique relative aux cookies en cliquant sur le lien suivant : https://support.twitter.com/articles/20170518#</li>
          </ul>
      </p>
      </v-col>
      <v-col cols="10">
        <p class="PrivacyPolicy--Text">
          <u>Paramétrage des cookies :</u>
          La grande majorité des navigateurs web prévoient la configuration par défaut, de manière à ce que le
dépôt de cookies soit autorisé. Cela étant, vous avez la possibilité de modifier ce paramètre par
défaut afin que la totalité ou une partie des cookies soient rejetés systématiquement.
      </p>
      </v-col>
      <v-col cols="10">
        <p class="PrivacyPolicy--Text">
          Vous devez comprendre que le fait de refuser le dépôt des cookies risque d’altérer votre expérience
utilisateur ainsi que de compromettre certaines fonctionnalités du site proposé par ACTION
PRÉVENTION.
      </p>
      </v-col>
      <v-col cols="10">
        <p class="PrivacyPolicy--Text">
          Le cas échéant, ACTION PRÉVENTION décline toute responsabilité concernant les conséquences liées
à la dégradation de vos conditions de navigation et de votre expérience utilisateur. Ces
conséquences ne sauraient constituer un dommage et vous ne pourrez prétendre à aucune
indemnité de ce fait.
      </p>
      </v-col>
      <v-col cols="10">
        <p class="PrivacyPolicy--Text">
          Votre navigateur vous permet également de supprimer les cookies existants sur votre terminal ou
encore de vous signaler lorsque de nouveaux cookies sont susceptibles d’être déposés sur votre
terminal. Ces paramétrages n’ont aucune incidence sur la navigation mais vous feront perdre tous les
avantages d’utilisation liés aux cookies.
      </p>
      </v-col>
      <v-col cols="10">
        <p class="PrivacyPolicy--Text">
          <u>Paramétrage du navigateur internet :</u>
          Afin de vous aider dans la modification de vos préférences liées aux cookies, vous trouverez ci-
dessous les liens vers les aides nécessaires pour accéder au menu de votre navigateur :
          <ul>
            <li>Chrome : https://support.google.com/chrome/answer/95647?hl=fr</li>
            <li>Firefox : https://support.mozilla.org/fr/kb/activer-desactiver-cookies</li>
            <li>Opera : http://help.opera.com/Windows/10.20/fr/cookies.html</li>
            <li>Safari : https://support.apple.com/fr-fr/guide/mdm/mdmf7d5714d4/web</li>
          </ul>
      </p>
      </v-col>
      <v-col cols="10">
        <p class="PrivacyPolicy--Text">
          Pour de plus amples informations concernant les outils de maîtrise des cookies, vous pouvez
consulter le site internet de la CNIL : <u>https://www.cnil.fr/fr/cookies-les-outils-pour-les-maitriser</u>.
      </p>
      </v-col>
      <v-col cols="10">
        <p class="PrivacyPolicy--Text">
          <u>Utilisation de cookies tiers :</u>
          Veuillez noter qu’ACTION PRÉVENTION utilise les services tiers pour connaître votre utilisation de ce
site, ceci afin d’optimiser votre expérience utilisateur. Ces tiers parties (services d’analyse de trafic
web…) peuvent également utiliser des cookies que nous ne maitrisons pas.
      </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import CguTitle from "../components/CguTitle.vue";

export default {
  name: "PrivacyPolicy",
  components: {
    CguTitle,
  },
  computed: {
    ...mapGetters(["getImage"]),
  },
};
</script>

<style lang="less" scoped>
@import "../styles/PrivacyPolicy/privacy-policy.less";
</style>