import getDefaultState from './stateInitializer'

export default {
  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
  },
  SET_SUBSCRIPTION(state, subscription) {
    state.subscription = subscription
    state.subscription.commercialCourts = subscription.metadata.commercialCourtsStringified?.split('¤') ?? []
    state.subscription.legalCourts = subscription.metadata.legalCourtsStringified?.split('¤') ?? []
  },
  SET_PARTNERSHIP_SUBSCRIPTION(state, subscription) {
    state.partnershipSubscription = subscription
  }
}
