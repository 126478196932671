<template>
  <v-menu
    v-model="showMenu"
    :close-on-content-click="false"
    :nudge-width="100"
    offset-y
    left
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        fab
        small
        :color="hasLogo ? 'white' : '#37A3C6'"
        dark
        v-bind="attrs"
        v-on="on"
        class="mr-5"
      >
        <v-img
          v-if="hasLogo"
          :src="getImage($store.state.login.user.enterprise.logo.path)"
          height="40"
          width="40"
          class="rounded-circle"
        />
        <span v-else>
          {{
            $store.state.login.user.firstName[0] +
            $store.state.login.user.lastName[0]
          }}
        </span>
      </v-btn>
    </template>

    <v-card>
      <v-list>
        <v-list-item>
          <div class="mr-5">
            <v-img
              v-if="hasLogo"
              :src="getImage($store.state.login.user.enterprise.logo.path)"
              width="40"
              height="40"
            />
            <span
              v-else
              class="ap-background-color white--text pa-3 text-uppercase"
            >
              {{
                $store.state.login.user.firstName[0] +
                $store.state.login.user.lastName[0]
              }}
            </span>
          </div>
          <v-list-item-content>
            <v-list-item-title>
              {{ $store.state.login.user.firstName }}
              {{ $store.state.login.user.lastName }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ $store.state.login.user.enterprise.denomination }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item
          to="/profile"
          active-class="no-active"
          @click="showMenu = false"
        >
          <v-list-item-title>Profil</v-list-item-title>
        </v-list-item>
        <v-list-item to="/logout" @click="showMenu = false">
          <v-list-item-title>Déconnexion</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { mapGetters } from "vuex";

const { mapState } = createNamespacedHelpers("navbar");

export default {
  data() {
    return {
      showMenu: false,
    };
  },
  computed: {
    ...mapGetters(["getImage"]),
    ...mapState(["menu"]),
    hasLogo() {
      return !!(
        this.$store.state.login.user.enterprise.logo &&
        this.$store.state.login.user.enterprise.logo.path
      );
    },
  },
};
</script>
