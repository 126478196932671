<template>
  <v-container id="whiteMark" fluid class="WhiteMark--Container">
    <v-row justify="center" class="mt-14">
      <v-col cols="12" md="9">
        <v-row class="WhiteMark--Logo pb-5">
          <v-col cols="12">
            <v-img
              alt="Logo Action Prevention"
              :src="getImage('white-mark.png')"
              width="45"
            />
          </v-col>
        </v-row>
        <h2>{{ $t("App.ProfessionalSpace.WhiteMark.Title") }}</h2>
      </v-col>
      <v-col cols="12" md="9">
        <div class="TitleUnderline ap-background-color"></div>
      </v-col>
    </v-row>
    <v-row justify="center" class="my-12">
      <v-col cols="12" md="9">
        <v-row>
          <v-col cols="12" sm="6">
            <v-row>
              <v-col cols="12" class="WhiteMark--Title">
                {{
                  $t(
                    "App.ProfessionalSpace.WhiteMark.ProvisionForCustomers.Title"
                  )
                }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="WhiteMark--Description text-justify">
                {{
                  $t(
                    "App.ProfessionalSpace.WhiteMark.ProvisionForCustomers.Description"
                  )
                }}
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6">
            <v-row>
              <v-col cols="12" class="WhiteMark--Title">
                {{
                  $t(
                    "App.ProfessionalSpace.WhiteMark.NaturalIncreaseInTraffic.Title"
                  )
                }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="WhiteMark--Description text-justify">
                {{
                  $t(
                    "App.ProfessionalSpace.WhiteMark.NaturalIncreaseInTraffic.Description"
                  )
                }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["getImage"]),
  },
};
</script>

<style lang="less" scoped>
@import "../../styles/ProfessionalSpace/white-mark.less";
</style>
