<template>
  <v-container fluid class="LexiconContent">
    <v-row justify="center" class="py-10">
      <v-col v-for="i in 34" :key ="i" cols="12" md="8" class="text-justify">
        <span
          class="LexiconContent--Description"
          v-html="$t('App.Lexicon.Presentation.Content.T' + i)"
        >
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "LexiconContent",
};
</script>

<style lang="less" scoped>
@import "../../styles/Lexicon/lexicon-content.less";
</style>
