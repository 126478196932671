<template>
  <v-container fluid class="pa-0">
    <div
      class="Footer--TopLine"
      :style="{ backgroundImage: `url(${getImage('back-home-page.jpeg')})` }"
    ></div>
    <v-container
      fluid
      class="Footer--Container"
      :class="{ 'px-16': $vuetify.breakpoint.mdAndUp }"
    >
      <v-row>
        <v-col cols="12" sm="6" class="Footer--Logo">
          <v-img
            alt="Logo Action Prevention"
            :src="getImage('logo-black.png')"
            width="202"
          />
        </v-col>
      </v-row>
      <v-row class="pt-10">
        <v-col cols="12" md="3">
          <v-row>
            <v-col cols="12" class="Footer--Title">
              {{ $t("App.Footer.About.Title") }}
            </v-col>
            <v-col cols="12">
              <div class="Footer--TitleUnderline"></div>
            </v-col>
            <v-col cols="12" class="Footer--Description">
              {{ $t("App.Footer.About.Description") }}
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-row justify="center">
            <v-col cols="12">
              <v-row>
                <v-col cols="12" class="Footer--Title">
                  {{ $t("App.Footer.UsefullLinks.Title") }}
                </v-col>
                <v-col cols="12">
                  <div class="Footer--TitleUnderline"></div>
                </v-col>
                <v-col cols="12" class="Footer--Link">
                  <a href="/carry-out-my-diagnosis" class="NavLink">{{
                    $t("App.Footer.UsefullLinks.PremierLien")
                  }}</a>
                </v-col>
                <v-col cols="12" class="Footer--Link">
                  <router-link to="/professional-space" class="NavLink">{{
                    $t("App.Footer.UsefullLinks.DeuxiemeLien")
                  }}</router-link>
                </v-col>
                <v-col cols="12" class="Footer--Link">
                  <router-link to="/about" class="NavLink">{{
                    $t("App.Footer.UsefullLinks.TroisiemeLien")
                  }}</router-link>
                </v-col>
                <v-col cols="12" class="Footer--Link">
                  <router-link to="/lexicon" class="NavLink">{{
                    $t("App.Footer.UsefullLinks.QuatriemeLien")
                  }}</router-link>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-row>
            <v-col cols="12" class="Footer--Title">
              {{ $t("App.Footer.TheyTrustUs") }}
            </v-col>
            <v-col cols="12">
              <div class="Footer--TitleUnderline"></div>
            </v-col>
            <v-col cols="12" class="Footer--About">
              <v-row>
                <v-col
                  v-for="partner in partners"
                  :key="partner.img"
                  cols="6"
                  sm="6"
                >
                  <a :href="partner.link" target="_blank">
                    <v-img
                      :alt="partner.alt"
                      :src="getImage('they-trust-us/' + partner.img)"
                    />
                  </a>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          md="3"
          :class="{ 'py-10': $vuetify.breakpoint.smAndDown }"
        >
          <v-row justify="center" align="center" class="fill-height">
            <v-btn
              href="mailto:gd@action-prevention.com"
              color="#37A3C6"
              tile
              :small="$vuetify.breakpoint.xs"
              :large="$vuetify.breakpoint.smAndUp"
              class="white--text"
            >
              <span>
                {{ $t("App.Footer.ContactUs") }}
              </span>
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid class="pa-0">
      <v-row no-gutters>
        <v-col class="Footer--Brand">
          <v-row no-gutters justify="center">
            <v-col cols="12" sm="auto">
              <span class="d-flex justify-center">
                {{ $t("App.Footer.Brand.ReservedRights") }}
              </span>
            </v-col>
            <!-- <v-col cols="auto">
              <router-link
                to="/legal-notice"
                class="float-right Footer--BrandLink"
              >
                &nbsp;//&nbsp;
                {{ $t("App.Footer.Brand.LegalNotice") }}
              </router-link>
            </v-col> -->
            <v-col cols="auto">
              <router-link
                to="/privacy-policy"
                class="float-right Footer--BrandLink"
              >
                &nbsp;//&nbsp;
                {{ $t("App.Footer.Brand.PrivacyPolicy") }}
              </router-link>
            </v-col>
            <v-col cols="auto">
              <router-link
                to="/cgu"
                class="float-right Footer--BrandLink"
              >
                &nbsp;//&nbsp;
                CGU
              </router-link>
            </v-col>
            <v-col cols="auto">
              <router-link
                to="/cgv"
                class="float-right Footer--BrandLink"
              >
                &nbsp;//&nbsp;
                CGV
              </router-link>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Footer",
  computed: {
    ...mapGetters(["getImage"]),
  },
  data() {
    return {
      partners: [
        {
          alt: "Bruzzo Dubucq",
          img: "bruzzo-dubucq.png",
          link: "https://bruzzodubucq.com"
        },
        {
          alt: "Greffe du tribunal de commerce Bobigny",
          img: "greffe-de-tribunal-de-commerce.png",
          link: "https://www.greffe-tc-bobigny.fr/"
        },
        {
          alt: "Médiation du crédit - Banque de France",
          img: "logo-mediation.png",
          link: "https://mediateur-credit.banque-france.fr"
        },
      ],
    };
  },
};
</script>
<style lang="less" scoped>
@import "../../styles/Footer/footer.less";
</style>
