<template>
  <v-container fluid class="PutInTouch--Container">
    <v-row id="putInTouch" justify="center" class="mt-14">
      <v-col cols="12" md="9">
        <v-row class="PutInTouch--Logo pb-5">
          <v-col cols="12">
            <v-img
              alt="Logo Action Prevention"
              :src="getImage('contact.png')"
              width="50"
            />
          </v-col>
        </v-row>
        <h2>{{ $t("App.ProfessionalSpace.PutInTouch.Title") }}</h2>
      </v-col>
      <v-col cols="12" md="9">
        <div class="TitleUnderline ap-background-color"></div>
      </v-col>
    </v-row>
    <v-row justify="center" class="my-12">
      <v-col cols="12" md="9">
        <v-row>
          <v-col cols="12" sm="6">
            <v-row>
              <v-col cols="12" class="PutInTouch--Title">
                {{
                  $t(
                    "App.ProfessionalSpace.PutInTouch.AppearOnPartnerWebsite.Title"
                  )
                }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="PutInTouch--Description text-justify">
                {{
                  $t(
                    "App.ProfessionalSpace.PutInTouch.AppearOnPartnerWebsite.Description"
                  )
                }}
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6">
            <v-row>
              <v-col cols="12" class="PutInTouch--Title">
                {{
                  $t("App.ProfessionalSpace.PutInTouch.ChooseYourClients.Title")
                }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="PutInTouch--Description text-justify">
                {{
                  $t(
                    "App.ProfessionalSpace.PutInTouch.ChooseYourClients.Description"
                  )
                }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["getImage"]),
  },
};
</script>

<style lang="less" scoped>
@import "../../styles/ProfessionalSpace/put-in-touch.less";
</style>
