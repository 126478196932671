<template>
  <transition name="slide" mode="out-in">
    <component v-bind:is="view" @display-view="displayView"></component>
  </transition>
</template>

<script>
import { mapState } from "vuex";
import HowItWorks from "@/views/Profile/BecomePartner/HowItWorks";
import IbanInfos from "@/views/Profile/BecomePartner/IbanInfos";
import SubscriptionRecap from "@/views/Profile/BecomePartner/SubscriptionRecap";

export default {
  name: "BecomePartner",
  components: {
    HowItWorks,
    IbanInfos,
    SubscriptionRecap,
  },
  data() {
    return {
      view: "",
    };
  },
  computed: {
    ...mapState({
      partnershipSubscription: (state) =>
        state.subscriptions.partnershipSubscription,
    }),
  },
  watch: {
    "partnershipSubscription.id": {
      handler() {
        this.checkDefaultStartup();
      },
      immediate: true,
    },
  },
  created() {
    this.checkDefaultStartup();
  },
  methods: {
    displayView(view) {
      this.view = view;
    },
    checkDefaultStartup() {
      if (this.partnershipSubscription.id) {
        this.view = "SubscriptionRecap";
      } else {
        this.view = "HowItWorks";
      }
    },
  },
};
</script>
