<template>
  <div id="lexicon">
    <Presentation />
    <LexiconContent />
  </div>
</template>

<script>
import Presentation from "@/views/Lexicon/Presentation.vue";
import LexiconContent from "@/views/Lexicon/LexiconContent.vue";

export default {
  name: "Lexicon",
  components: {
    Presentation,
    LexiconContent,
  },
};
</script>
