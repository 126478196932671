<template>
  <div class="redContainer">
    <div class="header__wrapper">
      <div class="header">
        <div class="sign"><span></span></div>
      </div>
    </div>
    <v-row no-gutters class="mt-4">
      <v-col>
        <v-row no-gutters justify="center">
          <h1>{{ title }}</h1>
        </v-row>
        <v-col> </v-col>
        <v-row no-gutters justify="center">
          <p>{{ subTitle }}</p>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "ErrorAnimation",
  props: {
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
    },
  },
};
</script>

<style scoped>
.header__wrapper {
  height: 200px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.header {
  -webkit-animation: headerAni 230ms ease-in 430ms forwards;
  animation: headerAni 230ms ease-in 430ms forwards;
  border-radius: 0;
  height: 700px;
  left: -200px;
  opacity: 0;
  position: absolute;
  top: -500px;
  width: 700px;
}
.header .sign {
  -webkit-animation: signAni 430ms ease-in 660ms forwards;
  animation: signAni 430ms ease-in 660ms forwards;
  border-radius: 50%;
  bottom: 50px;
  display: block;
  height: 100px;
  left: calc(50% - 50px);
  opacity: 0;
  position: absolute;
  width: 100px;
}

/*
 * COLOR SPECIFIC
*/
.redContainer .header {
  background-color: #ffb3b3;
}
.redContainer .sign {
  background-color: #ff3535;
  box-shadow: 0 0 0 15px #ff8282, 0 0 0 30px #ffa2a2;
}
.redContainer .sign:before,
.redContainer .sign:after {
  background: white;
  border-radius: 2px;
  content: "";
  display: block;
  height: 40px;
  left: calc(50% - 2px);
  position: absolute;
  top: calc(50% - 20px);
  width: 5px;
}
.redContainer .sign:before {
  transform: rotate(45deg);
}
.redContainer .sign:after {
  transform: rotate(-45deg);
}
.redContainer button:hover {
  border-color: #ff3535;
}
.redContainer button:focus {
  background-color: #ffb3b3;
  border-color: #ff3535;
}

/*
 * ANIMATIONS
*/
@-webkit-keyframes headerAni {
  0% {
    border-radius: 0;
    opacity: 0;
    transform: translateY(-100px);
  }
  100% {
    border-radius: 50%;
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes headerAni {
  0% {
    border-radius: 0;
    opacity: 0;
    transform: translateY(-100px);
  }
  100% {
    border-radius: 50%;
    opacity: 1;
    transform: translateY(0);
  }
}
@-webkit-keyframes signAni {
  0% {
    opacity: 0;
    transform: scale(0.3) rotate(180deg);
  }
  60% {
    transform: scale(1.3);
  }
  80% {
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1) rotate(0);
  }
}
@keyframes signAni {
  0% {
    opacity: 0;
    transform: scale(0.3) rotate(180deg);
  }
  60% {
    transform: scale(1.3);
  }
  80% {
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1) rotate(0);
  }
}
</style>