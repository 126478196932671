<template>
  <div id="carry-out-my-diagnosis">
    <v-container
      class="fill-height mb-5"
      :fluid="fluid"
      :class="{ 'pa-0': fluid }"
    >
      <action-prevention-diagnosis />
    </v-container>
  </div>
</template>

<script>
import ActionPreventionDiagnosis from "action-prevention-diagnosis";
export default {
  name: "CarryOutMyDiagnosis",
  components: {
    ActionPreventionDiagnosis,
  },
  data() {
    return {
      fluid: false,
    };
  },
  watch: {
    "$router.currentRoute.query.accessToken": {
      handler(token) {
        this.fluid = !!token;
      },
      immediate: true,
    },
  },
};
</script>

<style>
#carry-out-my-diagnosis {
  /* min-height: 500px; */
}
</style>
