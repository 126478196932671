export default {
  async search({ dispatch }, val) {
    try {
      return (await this._vm.$http.get(`geocoding/search?q=${val}&limit=5`)).data.features.map(feature => {
        return {
          id: feature.properties.id,
          label: feature.properties.label,
          longitude: feature.geometry.coordinates[0],
          latitude: feature.geometry.coordinates[1],
          postcode: feature.properties.postcode,
          city: feature.properties.city,
        }
      })
    } catch (e) {
      dispatch('showToast', {}, { root: true })
    }
  }
}
