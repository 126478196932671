<template>
  <div id="about">
    <Presentation />
    <OurVision />
    <OurApproach />
    <OurTeam />
  </div>
</template>

<script>
import Presentation from "@/views/About/Presentation.vue";
import OurVision from "@/views/About/OurVision.vue";
import OurApproach from "@/views/About/OurApproach.vue";
import OurTeam from "@/views/About/OurTeam.vue";

export default {
  name: "About",
  components: {
    Presentation,
    OurVision,
    OurApproach,
    OurTeam,
  },
};
</script>
