<template>
  <v-row no-gutters class="pt-5 BecomePartner_IbanInfos">
    <v-col cols="12" class="mt-5">
      <h1 class="BecomePartner_IbanInfos--Title">Vous êtes partenaire !</h1>
    </v-col>
    <v-col cols="12" class="mt-1">
      <span class="grey--text" v-if="!isSubscriptionSuspended">
        Votre abonnement se renouvellera le
        {{ partnershipSubscription.current_period_end | formatDate }}</span
      >
      <span v-else class="red--text">
        Votre abonnement prendra fin le
        {{ partnershipSubscription.cancel_at | formatDate }}</span
      >
    </v-col>
    <v-col cols="12" sm="9" class="mt-4">
      <div class="TitleUnderline ap-background-color"></div>
    </v-col>
    <v-col cols="12" class="mt-5">
      <h1 class="BecomePartner_IbanInfos--Title">Diagnostic</h1>
    </v-col>
    <v-col cols="12" class="mt-4">
      <v-alert outlined>
        <v-row no-gutters align="center">
          <v-col cols="12" md="10">
            <div>{{ proprietaryCode }}</div>
          </v-col>
          <v-col cols="12" md="2">
            <v-row no-gutters justify="end" align="center" class="fill-height">
              <v-btn
                large
                outlined
                class="ap-text-color"
                @click="copyProprietaryCode"
              >
                <span> <v-icon>mdi-content-copy</v-icon> </span>
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-alert>
    </v-col>
    <v-col cols="12" class="mt-5">
      <h1 class="BecomePartner_IbanInfos--Title">
        Site web d'Action Prévention
      </h1>
    </v-col>
    <v-col cols="12" class="mt-4">
      <v-alert outlined>
        <v-row no-gutters align="center">
          <v-col cols="12" md="10">
            <div>{{ fullSiteCode }}</div>
          </v-col>
          <v-col cols="12" md="2">
            <v-row no-gutters justify="end" align="center" class="fill-height">
              <v-btn
                large
                outlined
                class="ap-text-color"
                @click="copyFullSiteCode"
              >
                <span> <v-icon>mdi-content-copy</v-icon> </span>
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-alert>
    </v-col>
    <v-col cols="12" class="mt-5">
      <h1 class="BecomePartner_IbanInfos--Title">Informations bancaires</h1>
    </v-col>
    <v-col cols="12" class="mt-5">
      <v-alert outlined>
        <v-row no-gutters align="center">
          <v-col cols="12" lg="8">
            <v-row no-gutters>
              <v-col cols="12">
                <span>
                  IBAN:
                  {{
                    user.bankInfos.iban
                      | VMask("AA## #### #### #### #### #### ###")
                  }}
                </span>
              </v-col>
              <v-col cols="12">
                <span> BIC: {{ user.bankInfos.bic }} </span>
              </v-col>
              <v-col cols="12">
                <span> Titulaire: {{ user.bankInfos.titulaire }} </span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" lg="4">
            <v-row no-gutters justify="end" align="center" class="fill-height">
              <v-btn large outlined class="ap-text-color" @click="ibanStep">
                <span> Modifiers mon rib </span>
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-alert>
    </v-col>
    <v-col cols="12" class="mt-10">
      <v-btn
        v-if="hasSubscription && !isSubscriptionSuspended"
        tile
        x-large
        class="accent-4 red white--text"
        :class="{
          'mt-3': $vuetify.breakpoint.mdAndDown,
          'fill-width': !$vuetify.breakpoint.mdAndUp,
        }"
        :loading="cancelLoading"
        @click="showSuspendModal = true"
      >
        <span>Annuler mon abonnement</span>
      </v-btn>
      <v-btn
        v-if="hasSubscription && isSubscriptionSuspended"
        tile
        x-large
        :loading="reactivateLoading"
        class="accent-4 green white--text"
        :class="{
          'mt-3': $vuetify.breakpoint.mdAndDown,
          'fill-width': !$vuetify.breakpoint.mdAndUp,
        }"
        @click="reactivateSubscription"
      >
        Réactiver mon abonnement
      </v-btn>
    </v-col>

    <v-dialog v-model="showModal" hide-overlay width="300">
      <v-card>
        <SuccessAnimation
          v-if="!subscriptionError"
          title="Validé"
          :subTitle="successSubtitle"
        />
        <ErrorAnimation
          v-else
          title="Annulation"
          subTitle="Le paiement a été annulé !"
        />
      </v-card>
    </v-dialog>
    <v-dialog v-model="showSuspendModal" width="400">
      <v-card>
        <v-card-title class="text-h5 DefineTarget--ModalTitle">
          Voulez-vous vraiment annuler votre abonnement ?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :loading="cancelLoading"
            color="red darken-1"
            text
            @click="suspendSubscription"
          >
            Suspendre
          </v-btn>
          <v-btn color="darken-1" text @click="showSuspendModal = false">
            Retour
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import dayjs from "dayjs";
import SuccessAnimation from "@/components/SuccessAnimation";
import ErrorAnimation from "@/components/ErrorAnimation";
import Configuration from "../../../config";

export default {
  name: "IbanInfos",
  components: {
    SuccessAnimation,
    ErrorAnimation,
  },
  data() {
    return {
      id_userFromstorage: localStorage.getItem("id_user_coming_from"),
      iban: "",
      bic: "",
      titulaire: "",
      cancelLoading: false,
      reactivateLoading: false,
      subLoading: false,
      showSuspendModal: false,
      subscriptionError: false,
      showModal: false,
      rules: {
        titulaire: [(v) => !!v || "Un titulaire est requis"],
        iban: [
          (v) => !!v || "Un IBAN est requis",
          (v) =>
            /^FR[a-zA-Z0-9]{2}\s?([0-9]{4}\s?){2}([0-9]{2})([a-zA-Z0-9]{2}\s?)([a-zA-Z0-9]{4}\s?){2}([a-zA-Z0-9]{1})([0-9]{2})\s?$/.test(
              v
            ) || "IBAN invalide",
        ],
        bic: [
          (v) => !!v || "Un BIC est requis",
          (v) =>
            /^[A-Z]{6,6}[A-Z2-9][A-NP-Z0-9]([A-Z0-9]{3,3}){0,1}$/.test(v) ||
            "BIC invalide",
        ],
      },
    };
  },
  created() {
    this.iban = this.user.bankInfos.iban;
    this.bic = this.user.bankInfos.bic;
    this.titulaire = this.user.bankInfos.titulaire;
  },
  filters: {
    formatDate: function (date) {
      return dayjs.unix(date).format("DD/MM/YYYY");
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.login.user,
      partnershipSubscription: (state) =>
        state.subscriptions.partnershipSubscription,
    }),
    hasSubscription() {
      return !!this.partnershipSubscription.id;
    },
    successSubtitle() {
      return !this.hasSubscription
        ? "Abonnement validé !"
        : "Abonnement mis à jour !";
    },
    isSubscriptionSuspended() {
      return this.partnershipSubscription.cancel_at_period_end;
    },
    proprietaryCode() {
      return `<iframe width="100%" height="500" src="${Configuration.value(
        "diagStandaloneUrl"
      )}?id_user=${this.user.id}"></iframe>`;
    },
    fullSiteCode() {
      return `<iframe width="100%" height="500" src="${window.location.origin}?id_user=${this.user.id}"></iframe>`;
    },
  },
  methods: {
    async suspendSubscription() {
      this.cancelLoading = true;
      await this.$store.dispatch(
        "subscriptions/suspendSubscription",
        this.partnershipSubscription.id
      );
      this.cancelLoading = false;
      this.showSuspendModal = false;
    },
    async reactivateSubscription() {
      console.log("reac");
      this.reactivateLoading = true;
      await this.$store.dispatch(
        "subscriptions/reactivateSubscription",
        this.partnershipSubscription.id
      );
      this.reactivateLoading = false;
    },
    ibanStep() {
      this.$emit("display-view", "IbanInfos");
    },
    copyProprietaryCode() {
      navigator.clipboard.writeText(this.proprietaryCode);
      this.$store.dispatch("showToast", {
        text: "Code d'intégration du diagnostic copié !",
        color: "green",
      });
    },
    copyFullSiteCode() {
      navigator.clipboard.writeText(this.fullSiteCode);
      this.$store.dispatch("showToast", {
        text: "Code d'intégration du site copié !",
        color: "green",
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../../styles/Profile/become-partner_step-2.less";
</style>
