<template>
  <v-row no-gutters class="pt-5 BecomePartner_IbanInfos">
    <v-col cols="12" class="mt-5">
      <h1 class="BecomePartner_IbanInfos--Title">
        Veuillez renseigner votre RIB afin de recevoir vos paiements.
      </h1>
    </v-col>
    <v-col cols="12" sm="9" class="mt-4">
      <div class="TitleUnderline ap-background-color"></div>
    </v-col>
    <v-form ref="form">
      <v-row no-gutters>
        <v-col cols="12" class="mt-10">
          <v-text-field
            v-mask="'AA## #### #### #### #### #### ###'"
            prepend-inner-icon="mdi-bank-outline"
            :rules="rules.iban"
            v-model="iban"
            outlined
            validate-on-blur
          >
            <template #label>
              <span class="red--text"><strong>* </strong></span>
              IBAN
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-mask="'NNNNNNNN'"
            prepend-inner-icon="mdi-bank-outline"
            :rules="rules.bic"
            v-model="bic"
            outlined
            validate-on-blur
          >
            <template #label>
              <span class="red--text"><strong>* </strong></span>
              BIC
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            prepend-inner-icon="mdi-account-outline"
            :rules="rules.titulaire"
            v-model="titulaire"
            outlined
            validate-on-blur
          >
            <template #label>
              <span class="red--text"><strong>* </strong></span>
              Titulaire
            </template>
          </v-text-field>
        </v-col>
      </v-row>
    </v-form>
    <v-col cols="12" class="mt-6">
      <v-checkbox v-if="!partnershipSubscription.id" color="#37A3C6" v-model="cgvAccepted" class="DefineTarget--Checkbox" hide-details>
        <template v-slot:[`label`]>
          <span class="DefineTarget--Retract">En validant le paiement j'accepte les 
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <a :href="websiteUrl + '/cgv'" target="_blank"
                  @click.stop
                  v-on="on">CGV</a>
            </template> 
            S'ouvre dans une nouvelle fenêtre
          </v-tooltip>
          et je renonce expressément à mon droit de rétractation de 14 jours.</span>
        </template>
      </v-checkbox>
      <v-btn
        v-if="!partnershipSubscription.id"
        tile
        x-large
        color="#37A3C6"
        class="white--text"
        :outlined="!cgvAccepted"
        :disabled="!cgvAccepted"
        @click="pay"
        :loading="subLoading"
      >
        <span> Valider et passer au paiement </span>
      </v-btn>
      <v-btn
        v-if="partnershipSubscription.id"
        tile
        x-large
        color="#37A3C6"
        class="white--text"
        @click="majInfosIban"
        :loading="subLoading"
        :disabled="!hasIbanBeenModified"
      >
        <span> Mettre à jour mes infos </span>
      </v-btn>
    </v-col>

    <v-col cols="12" class="mt-4">
      <v-btn tile text x-large outlined color="#37A3C6" @click="backStep">
        <span> Retour </span>
      </v-btn>
    </v-col>
    <v-dialog v-model="showModal" hide-overlay width="300">
      <v-card>
        <SuccessAnimation title="Validé" :subTitle="successSubtitle" />
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import { isEqual } from "lodash/core";
import dayjs from "dayjs";
import PartnershipPrice from "@/enums/PartnershipPrice.enum";
import SuccessAnimation from "@/components/SuccessAnimation";

export default {
  name: "IbanInfos",
  components: {
    SuccessAnimation,
  },
  data() {
    return {
      iban: "",
      bic: "",
      titulaire: "",
      subLoading: false,
      showSuspendModal: false,
      showModal: false,
      cgvAccepted: false,
      websiteUrl: window.location.origin,
      rules: {
        titulaire: [(v) => !!v || "Un titulaire est requis"],
        iban: [
          (v) => !!v || "Un IBAN est requis",
          (v) =>
            /^FR[a-zA-Z0-9]{2}\s?([0-9]{4}\s?){2}([0-9]{2})([a-zA-Z0-9]{2}\s?)([a-zA-Z0-9]{4}\s?){2}([a-zA-Z0-9]{1})([0-9]{2})\s?$/.test(
              v
            ) || "IBAN invalide",
        ],
        bic: [
          (v) => !!v || "Un BIC est requis",
          (v) =>
            /^[A-Z]{6,6}[A-Z2-9][A-NP-Z0-9]([A-Z0-9]{3,3}){0,1}$/.test(v) ||
            "BIC invalide",
        ],
      },
    };
  },
  created() {
    this.iban = this.user.bankInfos.iban ?? "";
    this.bic = this.user.bankInfos.bic ?? "";
    this.titulaire = this.user.bankInfos.titulaire ?? "";
  },
  filters: {
    formatDate: function (date) {
      return dayjs.unix(date).format("DD/MM/YYYY");
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.login.user,
      partnershipSubscription: (state) =>
        state.subscriptions.partnershipSubscription,
    }),
    hasSubscription() {
      return !!this.partnershipSubscription.id;
    },
    successSubtitle() {
      return !this.hasSubscription
        ? "Abonnement validé !"
        : "Abonnement mis à jour !";
    },
    hasIbanBeenModified() {
      console.log(this.iban, this.user.bankInfos.iban);
      return (
        !isEqual(this.iban.replaceAll(" ", ""), this.user.bankInfos.iban) ||
        !isEqual(this.bic, this.user.bankInfos.bic) ||
        !isEqual(this.titulaire, this.user.bankInfos.titulaire)
      );
    },
  },
  methods: {
    async pay() {
      if (this.$refs.form.validate()) {
        if (this.user.stripeCustomerId) {
          this.majLoading = true;
          const isReqOk = await this.$store.dispatch(
            "subscriptions/addSubscription",
            {
              prices: [
                {
                  price: PartnershipPrice.Partnership,
                  quantity: 1,
                },
              ],
            }
          );

          if (isReqOk) {
            await this.majInfosIban();
            this.showModal = true;
          } else {
            await this.$store.dispatch("subscriptions/fetchSubscriptions");
          }
          this.majLoading = false;
        } else {
          this.subLoading = true;
          const url = await this.$store.dispatch(
            "subscriptions/createSubscription",
            {
              prices: [
                {
                  price: PartnershipPrice.Partnership,
                  quantity: 1,
                },
              ],
            }
          );
          if (url) {
            await this.majInfosIban();
            window.location = url;
          }
          this.subLoading = false;
        }
      }
    },
    async majInfosIban(showModal) {
      if (this.$refs.form.validate()) {
        this.subLoading = true;
        const updateOk = await this.$store.dispatch("login/majIbanInfos", {
          iban: this.iban.replaceAll(" ", ""),
          bic: this.bic,
          titulaire: this.titulaire,
        });
        if (updateOk) {
          if (showModal) {
            this.showModal = true;
          }
          await this.$store.dispatch("login/fetchUser");
        }
        this.subLoading = false;
      }
    },
    backStep() {
      if (this.hasSubscription) {
        this.$emit("display-view", "SubscriptionRecap");
      } else {
        this.$emit("display-view", "HowItWorks");
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../../styles/Profile/become-partner_step-2.less";
</style>
