<template>
  <v-container fluid class="TheyTrustUs--Container mb-10">
    <v-row justify="center" class="mt-14">
      <v-col cols="12" sm="9">
        <h2>{{ $t("App.Home.TheyTrustUs.Title") }}</h2>
      </v-col>
      <v-col cols="12" sm="9">
        <div class="TitleUnderline ap-background-color"></div>
      </v-col>
    </v-row>
    <v-row justify="center" class="mt-14">
      <v-col cols="12" sm="9">
        <v-row>
          <v-col
            v-for="partner in partners"
            :key="partner.img"
            cols="12"
            sm="6"
            md="4"
            class="px-6"
          >
          <a :href="partner.link" target="_blank">
            <v-img
              :alt="partner.alt"
              :src="getImage('they-trust-us/' + partner.img)"
            />
          </a>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TheyTrustUs",
  computed: {
    ...mapGetters(["getImage"]),
  },
  data() {
    return {
      partners: [
        {
          alt: "Bruzzo Dubucq",
          img: "bruzzo-dubucq.png",
          link: "https://bruzzodubucq.com"
        },
        {
          alt: "Greffe du tribunal de commerce Bobigny",
          img: "greffe-de-tribunal-de-commerce.png",
          link: "https://www.greffe-tc-bobigny.fr/"
        },
        {
          alt: "Médiation du crédit - Banque de France",
          img: "logo-mediation.png",
          link: "https://mediateur-credit.banque-france.fr"
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
@import "../../styles/Home/they-trust-us.less";
</style>
