<template>
  <v-col>
    <span class="article">Article {{ number }}</span> :
    <span class="title">{{ title }}</span>
  </v-col>
</template>

<script>
export default {
  name: "CguTitle",
  props: {
    number: Number,
    title: String,
  },
};
</script>

<style scoped>
.article {
  text-decoration: underline;
  text-transform: uppercase;
  font-weight: 300;
}

.title {
  text-transform: uppercase;
  font-weight: 300;
}
</style>