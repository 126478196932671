<template>
  <div id="professional-space">
    <Presentation />
    <EditBalanceSheet />
    <WhiteMark />
    <PutInTouch />
    <CreateMyAccount />
  </div>
</template>

<script>
import Presentation from "@/views/ProfessionalSpace/Presentation.vue";
import EditBalanceSheet from "@/views/ProfessionalSpace/EditBalanceSheet.vue";
import WhiteMark from "@/views/ProfessionalSpace/WhiteMark.vue";
import PutInTouch from "@/views/ProfessionalSpace/PutInTouch.vue";
import CreateMyAccount from "@/views/ProfessionalSpace/CreateMyAccount.vue";

export default {
  name: "Home",
  components: {
    Presentation,
    EditBalanceSheet,
    WhiteMark,
    PutInTouch,
    CreateMyAccount,
  },
};
</script>
