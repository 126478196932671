<template>
  <div id="login">
    <v-container class="Login--Container">
      <v-row>
        <v-col
          cols="12"
          sm="6"
          class="Login--LeftSideContainer"
          :style="{
            backgroundImage: `url(${getImage('backformulaire.jpg')})`,
          }"
        >
          <v-row no-gutters align="center" justify="center" class="fill-height">
            <div>
              <v-img
                alt="Logo Action Prevention"
                :src="getImage('logo-white.png')"
                width="50"
              />
            </div>
          </v-row>
        </v-col>
        <v-col cols="12" sm="6">
          <v-col cols="12">
            <h2>{{ $t("App.Login.Title") }}</h2>
            <p class="Login--SubTitle">{{ $t("App.Login.SubTitle") }}</p>
            <div class="TitleUnderline ap-background-color"></div>
          </v-col>
          <v-col cols="12">
            <transition name="slide" mode="out-in">
              <component
                v-bind:is="view"
                @display-view="displayView"
              ></component>
            </transition>
          </v-col>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SignIn from "@/views/Login/SignIn";
import SignUp from "@/views/Login/SignUp";
import ForgotPassword from "@/views/Login/ForgotPassword";
import RecoverPassword from "@/views/Login/RecoverPassword";

export default {
  name: "Login",
  components: {
    SignIn,
    SignUp,
    ForgotPassword,
    RecoverPassword,
  },
  created() {
    if (this.$route.params.token) {
      this.view = "RecoverPassword";
    }
  },
  data() {
    return {
      view: "SignIn",
    };
  },
  computed: {
    ...mapGetters(["getImage"]),
  },
  methods: {
    displayView(view) {
      this.$scrollTo("#app");
      this.view = view;
    },
  },
};
</script>

<style lang="less" scoped>
@import "../styles/Login/login.less";
</style>
