import getDefaultState from './stateInitializer'

export default {
  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
  },
  SET_USER(state, user) {
    state.user = user
  },
  SET_TOKEN(state, token) {
    state.token = token
  },
}
