<template>
  <v-container fluid class="OurTeam--Container">
    <v-row justify="center" class="mt-14">
      <span class="OurTeam--Title">{{ $t("App.About.OurTeam.Title") }}</span>
    </v-row>
    <v-row justify="center" align="center" class="mt-14">
      <v-col
        cols="12"
        sm="5"
        lg="2"
        v-for="employee in employees"
        :key="employee.photo"
      >
        <TeamCard v-bind="employee" />
      </v-col>
    </v-row>
    <v-row justify="center" class="my-10">
      <div class="OurTeam--BtnContainer">
        <v-btn
          href="mailto:gd@action-prevention.com"
          color="#37A3C6"
          tile
          x-large
          class="d-flex justify-center white--text"
        >
          <span>
            {{ $t("App.About.OurTeam.ContactUs") }}
          </span>
        </v-btn>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import TeamCard from "@/components/TeamCard.vue";

export default {
  name: "OurTeam",
  components: {
    TeamCard,
  },
  data() {
    return {
      employees: [
        {
          url: "https://bruzzodubucq.com",
          photo: "they-trust-us/bruzzo-dubucq.png",
        },
        {
          url: "https://www.greffe-tc-bobigny.fr",
          photo: "they-trust-us/greffe-de-tribunal-de-commerce.png",
        },
        {
          url: "https://mediateur-credit.banque-france.fr",
          photo: "they-trust-us/logo-mediation.png",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
@import "../../styles/About/our-team.less";
</style>
