<template>
  <v-container id="editBalanceSheet" fluid class="EditBalanceSheet--Container">
    <v-row justify="center" class="mt-14">
      <v-col cols="12" md="9">
        <v-row class="EditBalanceSheet--Logo pb-5">
          <v-col cols="12">
            <v-img
              alt="Logo Action Prevention"
              :src="getImage('edit-bese.png')"
              width="45"
            />
          </v-col>
        </v-row>
        <h2>{{ $t("App.ProfessionalSpace.EditBalanceSheet.Title") }}</h2>
      </v-col>
      <v-col cols="12" md="9">
        <div class="TitleUnderline ap-background-color"></div>
      </v-col>
    </v-row>
    <v-row justify="center" class="my-12">
      <v-col cols="12" md="9">
        <v-row>
          <v-col cols="12" sm="6">
            <v-row>
              <v-col cols="12" class="EditBalanceSheet--Title">
                {{
                  $t("App.ProfessionalSpace.EditBalanceSheet.SimpleFact.Title")
                }}
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                class="EditBalanceSheet--Description text-justify"
              >
                {{
                  $t(
                    "App.ProfessionalSpace.EditBalanceSheet.SimpleFact.Description"
                  )
                }}
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6">
            <v-row>
              <v-col cols="12" class="EditBalanceSheet--Title">
                {{
                  $t(
                    "App.ProfessionalSpace.EditBalanceSheet.AppropriateSolution.Title"
                  )
                }}
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                class="EditBalanceSheet--Description text-justify"
              >
                {{
                  $t(
                    "App.ProfessionalSpace.EditBalanceSheet.AppropriateSolution.Description"
                  )
                }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["getImage"]),
  },
};
</script>

<style lang="less" scoped>
@import "../../styles/ProfessionalSpace/edit-balance-sheet.less";
</style>
