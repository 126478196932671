<template>
  <v-container fluid id="profile" class="Profile--Container">
    <Header />
    <v-row class="mt-10">
      <v-container
        id="profileContainer"
        class="Profile--DataContainer"
        :class="{ 'pa-10': $vuetify.breakpoint.smAndUp }"
      >
        <v-row>
          <v-col cols="12" sm="6" md="4" class="Profile--LeftContainer">
            <UserInfos />
          </v-col>
          <v-col cols="12" sm="6" md="8">
            <v-tabs :value="tab" color="#37A3C6" right>
              <v-tab>Définir le ciblage</v-tab>
              <v-tab>Devenir partenaire</v-tab>
              <v-tab>Mes infos</v-tab>
              <v-tab-item>
                <DefineTarget />
              </v-tab-item>
              <v-tab-item>
                <BecomePartner />
              </v-tab-item>
              <v-tab-item>
                <UpdateUserInfos />
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
import UpdateUserInfos from "@/views/Profile/UpdateUserInfos";
import UserInfos from "@/views/Profile/UserInfos";
import Header from "@/views/Profile/Header";
import DefineTarget from "@/views/Profile/DefineTarget";
import BecomePartner from "@/views/Profile/BecomePartner";
import { omitBy } from "lodash";

export default {
  name: "Profile",
  components: {
    Header,
    UserInfos,
    DefineTarget,
    BecomePartner,
    UpdateUserInfos,
  },
  data() {
    return {
      tab: 0,
    };
  },
  mounted() {
    if (this.$router.currentRoute.query["become-partner"] === null) {
      this.tab = 1;
      var newRouteQuery = omitBy(this.$route.query, (val, key) =>
        ["become-partner"].includes(key)
      );
      this.$router.replace({ query: newRouteQuery });
    }
  },
};
</script>

<style lang="less" scoped>
@import "../styles/Profile/profile.less";
</style>
