<template>
  <v-container
    fluid
    class="Presentation--Container"
    :style="{ backgroundImage: `url(${getImage('back-home-page.jpeg')})` }"
  >
    <v-row justify="center">
      <span class="Presentation--Title">{{ $t("App.NavBar.About") }}</span>
    </v-row>
    <v-row justify="center" class="Presentation--Description">
      <p>{{ $t("App.About.Presentation.Description") }}</p>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Presentation",
  computed: {
    ...mapGetters(["getImage"]),
  },
};
</script>

<style lang="less" scoped>
@import "../../styles/About/presentation.less";
</style>
